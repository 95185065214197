import { css } from '@emotion/css'
import { backgroundGrey } from '@pp/common/css/colors'
import { increaseSpecificity, midHeightQuery } from '@pp/common/css/css.helper'
import { useMediaQuery } from '@react-hook/media-query'
import { Menu } from 'semantic-ui-react'

import BuildYourOwnChartsMenu from './byoc-menu/BuildYourOwnChartsMenu'
import { DefaultMenusProps } from './HorizontalMenus'
import { AnalyzeMenu, DashboardMenu, InsightsMenu, ResourcesMenu, SearchMenu } from '..'
import { useStores } from '../../../../../../store/useStore.hook'

const menuItem = css`
  ${increaseSpecificity(`
  font-size: 1em;
  padding: 0;

  &:before {
      background-color: ${backgroundGrey};
  }
`)}
`

export type Menus = {
  analyzeMenu: boolean
  checkMenu: boolean
  resourceSubmenu: boolean
  searchMenu: boolean
}

type Props = DefaultMenusProps & {
  active: string
  isMobile: boolean
  menus: Menus
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
  setShowMenus: React.Dispatch<React.SetStateAction<Menus>>
}

const VerticalMenus = ({ menus, setShowMenus, isMobile, minimizedSideBar, active, onNavLinkClick }: Props) => {
  const isSmallHeight = useMediaQuery(midHeightQuery) || isMobile
  const { applicationStore } = useStores()

  return (
    <>
      <Menu.Item className={menuItem}>
        <AnalyzeMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          isSmallHeight={isSmallHeight}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <SearchMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          isSmallHeight={isSmallHeight}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <InsightsMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          isSmallHeight={isSmallHeight}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <DashboardMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          isMobile={isMobile}
        />
      </Menu.Item>
      {applicationStore.hasCustomCharts ? (
        <Menu.Item className={menuItem}>
          <BuildYourOwnChartsMenu
            active={active}
            onNavLinkClick={onNavLinkClick}
            minimizedSideBar={minimizedSideBar}
            menus={menus}
            isMobile={isMobile}
          />
        </Menu.Item>
      ) : null}
      <Menu.Item className={menuItem}>
        <ResourcesMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          isSmallHeight={isSmallHeight}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
        />
      </Menu.Item>
    </>
  )
}

export default VerticalMenus
