import { SyntheticEvent, useEffect, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import { css, cx } from '@emotion/css'
import { t } from '@lingui/macro'
import { printCss } from '@pp/common/chart/config/charts-config'
import { primaryGreen } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { Popup, Icon, Form, Input, Button } from 'semantic-ui-react'

import { useIsChartSaved } from './useIsChartSaved.hook'
import { mapBuilderPurpose, prepareFilters } from '../../helpers'

const dashboardIconCss = (active: boolean) => css`
  ${increaseSpecificity(`
  color: ${active ? primaryGreen : 'rgba(0, 0, 0, 0.87)'};
  font-size: 0.9em;
  margin-right: 5px;
  margin-top: 1px;
  cursor: pointer;
`)}
`

const iconCss = css`
  color: ${primaryGreen};
`

type Props = { chartType?: string; testId: string; title?: string }

const SAVE_CHART_CONFIGURATION = gql`
  mutation saveChartConfiguration($chartConfiguration: ChartConfigurationInput!) {
    saveChartConfiguration(chartConfiguration: $chartConfiguration) {
      name
      chartType
      chartFilter
    }
  }
`

const SaveToDashboard = ({ chartType, testId, title }: Props) => {
  const { applicationStore, filterStore } = useStores()
  const { isChartSaved, entities, entityType } = useIsChartSaved({ chartType })
  const [checkmark, setCheckmark] = useState<boolean>(isChartSaved)
  const [chartName, setChartName] = useState<string>(title?.replace('<br>', ' ') || '')
  const [saveChartConfiguration, { loading: saveLoading, data }] = useMutation(SAVE_CHART_CONFIGURATION)

  const onSaveToDashboard = () => {
    const mappedActiveFilters = mapBuilderPurpose(filterStore.activeFilters, chartType)
    const chartFilter = JSON.stringify(
      prepareFilters(mappedActiveFilters, entityType, applicationStore.metadata, entities),
    )
    saveChartConfiguration({ variables: { chartConfiguration: { name: chartName, chartType, chartFilter } } })
  }

  const onDashboardNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setChartName(event.currentTarget.value)
  }

  useEffect(() => {
    if (data?.saveChartConfiguration) {
      setCheckmark(true)
      applicationStore.setChartConfigurations(data?.saveChartConfiguration)
    }
  }, [applicationStore, data])

  if (applicationStore.hasDashboards)
    return (
      <Popup
        trigger={
          <Icon
            className={cx(dashboardIconCss(isChartSaved), printCss)}
            name="grid layout"
            data-test-id={`${testId}-dashboard-icon`}
            title={t`saveToDashboard.addIcon.title`}
          />
        }
        content={
          <div>
            <Form onSubmit={onSaveToDashboard}>
              <Form.Field>
                <label>Chart Name</label>
                <Input
                  placeholder="Chart Name"
                  type="text"
                  name="chart-name"
                  value={chartName}
                  onChange={onDashboardNameChange}
                />
              </Form.Field>
              <Button type="submit" disabled={chartName === ''} loading={saveLoading}>
                Add to Dashboard
              </Button>
              {checkmark ? <Icon name="checkmark" className={iconCss} /> : null}
            </Form>
          </div>
        }
        on="click"
        position="bottom center"
      />
    )

  return (
    <Icon
      className={cx(dashboardIconCss(isChartSaved), printCss)}
      name="grid layout"
      data-test-id={`${testId}-dashboard-icon`}
      title={t`saveToDashboard.addIcon.title`}
      onClick={() => applicationStore.setModal({ open: true, version: '' })}
    />
  )
}

export default SaveToDashboard
