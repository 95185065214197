import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { Menu, Icon, Popup } from 'semantic-ui-react'

import ResourcesItems from './ResourcesItems'
import { resourcesRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const ResourcesMenu = ({ active, minimizedSideBar, isSmallHeight, menus, setShowMenus, isMobile }: MenuProps) => {
  const handleClick = () =>
    setShowMenus((prevState) => ({
      ...(isSmallHeight ? { analyzeMenu: false, searchMenu: false, checkMenu: false } : prevState),
      resourceSubmenu: !prevState.resourceSubmenu,
    }))

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.resources" />
            </Menu.Header>
            <ResourcesItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <Menu.Header
            className={cx(
              menuHeaderCss(isMobile, minimizedSideBar),
              resourcesRoutes.indexOf(active) > -1 ? 'active' : '',
            )}
            onClick={handleClick}
          >
            <Icon name="info circle" />
            {minimizedSideBar ? null : <Trans id="sidebar.buttons.resources" />}
          </Menu.Header>
        }
      />

      {minimizedSideBar ? null : (
        <ResourcesItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
      )}
    </>
  )
}

export default ResourcesMenu
