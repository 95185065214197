import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { Menu, Label } from 'semantic-ui-react'

import { disabledStyleCss, slideOutCss, submenuCss } from '../../../sidebar.css'
import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const InsightsItems = ({ menus, minimizedSideBar, isMobile, active }: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()
  return (
    <Menu.Menu
      className={cx(submenuCss(false, isMobile, minimizedSideBar), !minimizedSideBar && slideOutCss(menus.checkMenu))}
    >
      <Menu.Item>
        {applicationStore.hasFilingCheck ? (
          <NavLink className={active.includes(routes.filingCheckRoute) ? 'active' : ''} to={routes.filingCheckRoute}>
            <Trans id="header.title.filing-check" />
          </NavLink>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="header.title.filing-check" />
          </div>
        )}
      </Menu.Item>

      <Menu.Item>
        {applicationStore.hasWhiteSpotCheck ? (
          <NavLink
            className={active.includes(routes.whitespotCheckRoute) ? 'active' : ''}
            to={routes.whitespotCheckRoute}
          >
            <Trans id="sidebar.menu.whitespot" />
          </NavLink>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.whitespot" />
          </div>
        )}
      </Menu.Item>

      <Menu.Item>
        {applicationStore.hasSimilarity ? (
          <NavLink className={active.includes(routes.similarityRoute) ? 'active' : ''} to={routes.similarityRoute}>
            <Trans id="header.title.similarity" />
            <Label color="orange" size="mini" style={{ marginLeft: '0.3rem' }}>
              <Trans id="Beta" />
            </Label>
          </NavLink>
        ) : null}
      </Menu.Item>

      <Menu.Item>
        {applicationStore.hasMutuality ? (
          <NavLink className={active.includes(routes.mutualityRoute) ? 'active' : ''} to={routes.mutualityRoute}>
            <Trans id="header.title.mutuality" />
            <Label color="orange" size="mini" style={{ marginLeft: '0.3rem' }}>
              <Trans id="Beta" />
            </Label>
          </NavLink>
        ) : null}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(InsightsItems)
