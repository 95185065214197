import { css } from '@emotion/css'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconLogo from '@pp/assets/ip-icon.webp'
import Logo from '@pp/assets/ip-logo.svg'
import { mobileHeaderHeight, increaseSpecificity, sidebarWidth } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { NavLink } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'

import { mobileLogoCss } from '../../mobile-menu/MobileMenu'

const logoItemCss = css`
  ${increaseSpecificity(`
  background-color: white;
  position: static;
  min-width: ${sidebarWidth};
  padding: 17px 14px 17px;
`)}
`

const logoCss = css`
  ${increaseSpecificity(`
  width: 80%;
  margin: 0 auto;
`)}
`

const imageContainerCss = css`
  margin: auto;
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`

const imageCss = css`
  height: ${mobileHeaderHeight};
  padding: 8px 8px 8px 0;
`

const iconCss = css`
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
`

const minimizedLogoItemCss = css`
  ${increaseSpecificity(`
    font-size: 1em;
    background-color: white;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center
  `)}
`
type Props = { isMobile: boolean; minimizedSideBar: boolean; setSidebarVisible: (sidebarVisible: boolean) => void }

const SidebarLogo = ({ isMobile, setSidebarVisible, minimizedSideBar }: Props) => {
  return isMobile ? (
    <>
      <div className={imageContainerCss}>
        <Image className={imageCss} src={Logo} alt="logo" />
      </div>
      <FontAwesomeIcon className={iconCss} icon={faXmark} size="3x" onClick={() => setSidebarVisible(false)} />
    </>
  ) : (
    <Menu.Item className={minimizedSideBar ? minimizedLogoItemCss : logoItemCss}>
      <NavLink to={routes.homepageRoute}>
        <Image
          className={minimizedSideBar ? mobileLogoCss : logoCss}
          src={minimizedSideBar ? IconLogo : Logo}
          alt="logo"
        />
      </NavLink>
    </Menu.Item>
  )
}

export default SidebarLogo
