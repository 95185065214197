import { ReactNode } from 'react'

import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import IPIconInverted from '@pp/assets/ip-icon-inverted.svg'
import IPIcon from '@pp/assets/ip-icon-white.svg'
import PatentsIconInverted from '@pp/assets/patents-icon-inverted.svg'
import PatentsIcon from '@pp/assets/patents-icon.svg'
import TmIconInverted from '@pp/assets/tm-icon-inverted.svg'
import TmIcon from '@pp/assets/tm-icon.svg'
import { primaryGreen, white } from '@pp/common/css/colors'
import { Image } from 'semantic-ui-react'

const getIcons = (translationKey: string) => {
  if (translationKey === 'sidebar.buttons.patents') return [PatentsIcon, PatentsIconInverted]
  if (translationKey === 'sidebar.buttons.trademarks') return [TmIcon, TmIconInverted]
  return [IPIcon, IPIconInverted]
}

const customButtonCss = (isActive: boolean, btnColor = primaryGreen, showText: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${isActive ? 'auto' : 'pointer'};
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: ${isActive ? `${btnColor}` : '#E0E1E2'} none;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0em 0.25em 0em 0em;
  padding: ${showText ? '0.78571429em 1.5em 0.78571429em' : '0.4em 0 0.4em 0'};
  text-transform: none;
  text-shadow: none;
  font-weight: bold;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  will-change: '';
  width: ${showText ? '100px' : '40px'};
  margin: 0 auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${!isActive &&
  `&:hover {
    background-color: #babbbc;
    background-image: '';
    color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 0px 0px 1px transparent inset, none;
  }`}

  &:disabled {
    background-color: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    cursor: default;
  }
`

const buttonIconHolderCss = (showText: boolean) => css`
  width: 20px;
  height: 20px;
  margin-right: ${showText ? '5px' : '0px'};
`

const buttonTextCss = (active: boolean) => css`
  font-size: 1.4em;
  color: ${active ? white : 'default'};
  width: 29px;
  text-align: left;
`

type CustomButtonProps = {
  active: boolean
  btnColor?: string
  children?: ReactNode
  disabled?: boolean
  handleClick?: () => void
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>
  showText?: boolean
  translationKey: string
}

function CustomButton({
  active,
  translationKey,
  disabled = false,
  children,
  btnColor,
  showText = true,
  handleClick,
  ...rest
}: CustomButtonProps) {
  const { onMouseEnter, onMouseLeave } = rest
  const [icon, invertedIcon] = getIcons(translationKey)
  return (
    <button
      className={customButtonCss(active, btnColor, showText)}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={buttonIconHolderCss(showText)}>
        <Image src={active ? invertedIcon : icon} alt="ip-thumbnail" width="20px" height="20px" />
      </div>
      {showText ? (
        <div className={buttonTextCss(active)}>
          <Trans id={translationKey} />
        </div>
      ) : null}
      {children}
    </button>
  )
}

export default CustomButton
