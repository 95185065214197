import { css } from '@emotion/css'
import chroma from 'chroma-js'

export const colors = [
  '#154360',
  '#85C1E9',
  '#96C346',
  '#FFC000',
  '#566573',
  '#196F3D',
  '#7B241C',
  '#2E86C1',
  '#AED6F1',
  '#784212',
]

export const defaultThreshold = 0.005
export const noThreshold = 0
export const receivedScale = chroma.scale(['#85c1e9', '#154360'])
export const sentScale = chroma.scale(['#f5a544', '#9e3d22'])
export const createScaleFromBaseColor = (baseColor: string) =>
  chroma.scale([chroma(baseColor).brighten(2).hex(), baseColor])
export const inactiveColor = '#cccccc'
export const defaultChartHeight = 250
export const stackedBarChartHeight = 130

export const printCss = css({
  '&&&&&&&': {
    '@media print': {
      display: 'none',
    },
  },
})

export const printHeaderCss = css({
  '&&&&&': {
    display: 'none',
    '@media print': {
      display: 'block',
      fontSize: '2em',
    },
  },
})

export const parentPageBreak = css({
  '&&&&&': {
    '@media print': {
      display: 'block',
      position: 'relative',

      pageBreakAfter: 'always',

      '&:last-child': {
        pageBreakAfter: 'avoid',
      },
    },
  },
})

export const pageBreakInside = css({
  '&&&&&': {
    '&:nth-of-type(2)::before': {
      height: 0,
    },

    '@media print': {
      '::before': {
        display: 'none',
      },
      position: 'relative',
      pageBreakInside: 'avoid',

      '.column': {
        padding: '0.5rem !important',
        overflow: 'hidden',
      },
    },
  },
})

export const gridRowInGrid = css({
  '&&&&&': {
    '@media print': {
      '.column': {
        padding: '0rem !important',
      },
    },
  },
})

export const pageBreakAfter = css({
  '&&&&&': {
    '@media print': {
      '::before': {
        display: 'none',
      },
      position: 'relative',
      pageBreakAfter: 'avoid',
      pageBreakBefore: 'auto',
    },
  },
})
