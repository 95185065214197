import { css } from '@emotion/css'
import Logo from '@pp/assets/ip-icon.webp'
import { increaseSpecificity, mobileHeaderHeight } from '@pp/common/css/css.helper'
import { useToggleFamilyType } from '@pp/common/custom-hooks/useToggleFamilyType.hook'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { Icon, Image } from 'semantic-ui-react'

import { SmallProductsButtons } from '../sidebar/components'

const headerMenuCss = css`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  z-index: 9;
`
export const mobileLogoCss = css`
  height: ${mobileHeaderHeight};
  padding: 8px;
`
const headerCss = css`
  position: fixed;
  top: 0;
  z-index: 12;
  width: 100%;
  background-color: white;
  color: black;
`
const hamburgerIconCss = css`
  ${increaseSpecificity(`
  margin: 10px;
`)}
`

function MobileMenu({
  setSidebarVisible,
  sidebarVisible,
}: {
  setSidebarVisible: (sidebarVisible: boolean) => void
  sidebarVisible: boolean
}) {
  useToggleFamilyType()
  const handleBurgerClick = () => {
    setSidebarVisible(!sidebarVisible)
  }

  return (
    <div className={headerCss}>
      <div className={headerMenuCss}>
        <NavLink to={routes.mainRoute}>
          <Image className={mobileLogoCss} src={Logo} alt="logo" height="50px" width="50px" />
        </NavLink>
        <SmallProductsButtons isMobile={false} minimizedSideBar={true} isSidebarBreakpoint={true} />
        <Icon className={hamburgerIconCss} name="bars" size="big" onClick={handleBurgerClick} />
      </div>
    </div>
  )
}

export default observer(MobileMenu)
