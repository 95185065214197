import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

import { Results } from './MutualityContainer'

export type ServerFilters = {
  date_from: string[]
  date_to: string[]
  date_type: DateType[]
  family_type: FamilyType[]
  rep_url: string[]
  target_rep_url: string[]
}

type MutualitySearchParams = {
  dateType: string
  yearFrom: number
  yearTo: number
}

type SearchParameterKeys = keyof MutualitySearchParams
type OptionalMutualitySearchParams = Partial<MutualitySearchParams>

type MutualityInterface = {
  focalFirms: EntitySearchResult[]
  results: Partial<Results> | null
  searchParameters: MutualitySearchParams
  targetFirms: EntitySearchResult[]
}

export type TargetType = 'LF' | 'A'

const initialSearchParameters: MutualitySearchParams = {
  dateType: 'm',
  yearFrom: 2018,
  yearTo: new Date().getFullYear(),
}

export class MutualityStore implements MutualityInterface {
  private rootStore: RootStoreInterface
  focalFirms: EntitySearchResult[] = []
  targetFirms: EntitySearchResult[] = []
  targetType: TargetType = 'LF'
  results: Partial<Results> | null = null
  searchParameters = initialSearchParameters

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get serverFilters() {
    const filter = {
      family_type: this.rootStore.applicationStore.familyType,
      date_from: [this.searchParameters.yearFrom.toString()],
      date_to: [this.searchParameters.yearTo.toString()],
      date_type: [this.searchParameters.dateType.toUpperCase() as DateType],
      rep_url: this.focalFirms.map((el) => el.value),
      target_rep_url: this.targetFirms.map((el) => el.value),
    }

    return filter
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => (this.focalFirms = focalFirms)
  setTargetFirms = (targetFirms: EntitySearchResult[]) => (this.targetFirms = targetFirms)
  setResults = (results: object | null) => (this.results = results)
  setTargetType = (targetType: TargetType) => (this.targetType = targetType)

  setSearchParameter = (searchParameter: OptionalMutualitySearchParams) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: SearchParameterKeys) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter] as never
  }

  resetSearchUI = () => {
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const familyType = urlSearchParams.get('familyType')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const targetType = urlSearchParams.get('target_type')
    if (targetType) this.targetType = targetType as TargetType

    this.searchParameters.yearFrom = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.yearFrom

    this.searchParameters.yearTo = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.yearTo

    this.searchParameters.dateType = urlSearchParams.get('date_type') || initialSearchParameters.dateType
  }

  updateSearchParamsFromFilters = (filters: ServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]

    if (filters.rep_url.length) searchStrings.push(`rep_url=${filters.rep_url.join(',')}`)
    if (filters.target_rep_url.length) searchStrings.push(`target_rep_url=${filters.target_rep_url.join(',')}`)
    searchStrings.push(`date_to=${filters.date_to}`)
    searchStrings.push(`date_from=${filters.date_from}`)
    searchStrings.push(`date_type=${filters.date_type}`)
    searchStrings.push(`target_type=${this.targetType}`)

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?familyType=${familyType}&${searchStrings.join('&')}`)
  }
}
