import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { cx } from '@emotion/css'
import { faEquals, faNotEqual } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/react'
import { premiumMessageCss } from '@pp/common/chart/components/chart-holder/ChartHolder'
import { printCss } from '@pp/common/chart/config/charts-config'
import {
  midHeightQuery,
  sidebarHeightBreakpoint,
  tinyHeightQuery,
  sidebarWidthBreakpoint,
} from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { Sidebar as SUISidebar, Icon, Menu, Popup, Button } from 'semantic-ui-react'

import { FooterButtons, HorizontalMenus, ProductsButtons, SidebarLogo, VerticalMenus } from './components'
import { analyzeRoutes } from './route-groups'
import {
  buttonMenuItemCss,
  buttonsContainerCss,
  flexContainerCss,
  loginMessageCss,
  logoContainerCss,
  minimizedSidebarCss,
  sidebarCss,
  sliderCss,
} from './sidebar.css'

export const premiumMessageBlock = (
  <div data-test-id="permission-message">
    <p className={premiumMessageCss}>
      <Icon name="lock" />
      <Trans id="plans.notice.premium.headline" />
    </p>
    <p className={premiumMessageCss}>
      <i>
        <Trans id="plans.notice.premium.message" />
      </i>
    </p>
  </div>
)

function Sidebar({
  isMobile,
  sidebarVisible,
  setSidebarVisible,
}: {
  isMobile: boolean
  setSidebarVisible: (sidebarVisible: boolean) => void
  sidebarVisible: boolean
}) {
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuth0()
  const { routerStore, applicationStore, filterStore } = useStores()
  const [active, setActive] = useState(routes.agentsRoute)
  const isSmallHeight = useMediaQuery(midHeightQuery) || isMobile
  const isSidebarHeightBreakpoint = useMediaQuery(sidebarHeightBreakpoint)
  const isSidebarWidthBreakpoint = useMediaQuery(sidebarWidthBreakpoint)
  const isTinyHeightQuery = useMediaQuery(tinyHeightQuery)
  const isNegativeFiltersVisible =
    applicationStore.hasNegativeFilters && [...analyzeRoutes].some((route) => pathname.includes(route))
  const [negativeFiltersVisible, setNegativeFiltersVisible] = useState(isNegativeFiltersVisible)
  const { minimizedSideBar, toggleMinimizedSidebar, closeMinimizedSidebar } = applicationStore

  const [menus, setShowMenus] = useState({
    analyzeMenu: !isSmallHeight,
    searchMenu: !isSmallHeight,
    checkMenu: !isSmallHeight,
    resourceSubmenu: false,
  })

  useEffect(() => {
    if (!minimizedSideBar && (isSidebarHeightBreakpoint || isSidebarWidthBreakpoint)) toggleMinimizedSidebar()
    if (minimizedSideBar && (!isSidebarHeightBreakpoint || !isSidebarWidthBreakpoint)) toggleMinimizedSidebar()
    if (isMobile) closeMinimizedSidebar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarHeightBreakpoint, isSidebarWidthBreakpoint])

  useEffect(() => {
    setShowMenus((prevState) => ({
      ...prevState,
      analyzeMenu: !isSmallHeight,
      searchMenu: !isSmallHeight,
      checkMenu: !isSmallHeight,
    }))
  }, [isSmallHeight])

  useEffect(() => {
    setActive(`/${pathname.split('/')[1]}`)
    setNegativeFiltersVisible(isNegativeFiltersVisible)
  }, [pathname, applicationStore.hasNegativeFilters, isNegativeFiltersVisible])

  const onNavLinkClick = (route: string) => {
    routerStore.push(route)
    isMobile && setSidebarVisible(false)
  }

  return (
    <SUISidebar
      as={Menu}
      className={cx(sidebarCss(isMobile), printCss, minimizedSideBar && sliderCss)}
      animation="overlay"
      vertical
      visible={sidebarVisible}
    >
      <div className={flexContainerCss}>
        <div className={logoContainerCss(isMobile)}>
          <SidebarLogo isMobile={isMobile} setSidebarVisible={setSidebarVisible} minimizedSideBar={minimizedSideBar} />

          {isTinyHeightQuery ? (
            <HorizontalMenus menus={menus} isMobile={isMobile} active={active} onNavLinkClick={onNavLinkClick} />
          ) : (
            <VerticalMenus
              menus={menus}
              setShowMenus={setShowMenus}
              isMobile={isMobile}
              minimizedSideBar={minimizedSideBar}
              active={active}
              onNavLinkClick={onNavLinkClick}
            />
          )}
        </div>

        <div>
          <div>
            <div className={isSidebarHeightBreakpoint && !minimizedSideBar ? buttonsContainerCss : ''}>
              {negativeFiltersVisible && !minimizedSideBar ? (
                <Menu.Item className={buttonMenuItemCss(isSidebarHeightBreakpoint)}>
                  <Button.Group>
                    <Popup
                      size="mini"
                      content={<Trans id="sidebar.buttons.tooltip.inclusiveFilter" />}
                      trigger={
                        <Button
                          icon
                          primary={!filterStore.negativeFilter}
                          onClick={() => filterStore.setNegativeFilter(false)}
                        >
                          <FontAwesomeIcon icon={faEquals} />
                        </Button>
                      }
                    />
                    <Popup
                      size="mini"
                      content={<Trans id="sidebar.buttons.tooltip.exclusiveFilter" />}
                      trigger={
                        <Button
                          icon
                          primary={filterStore.negativeFilter}
                          onClick={() => filterStore.setNegativeFilter(true)}
                        >
                          <FontAwesomeIcon icon={faNotEqual} />
                        </Button>
                      }
                    />
                  </Button.Group>
                </Menu.Item>
              ) : null}

              {isMobile ? null : <ProductsButtons minimizedSideBar={minimizedSideBar} />}
            </div>

            {isAuthenticated ? null : (
              <div className={loginMessageCss}>
                <Trans id="sidebar.notLoggedInMessage" />
              </div>
            )}
            <FooterButtons
              active={active}
              onNavLinkClick={onNavLinkClick}
              minimizedSideBar={minimizedSideBar}
              isSmallHeight={isSmallHeight}
              isMobile={isMobile}
            />
            {isMobile || !isAuthenticated ? null : (
              <div className={minimizedSidebarCss} onClick={toggleMinimizedSidebar}>
                <Icon name={minimizedSideBar ? 'angle double right' : 'angle double left'} style={{ margin: '0px' }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </SUISidebar>
  )
}

export default observer(Sidebar)
