export const primaryGreen = '#96c347'
export const primaryGreenHover = '#99b05c'
export const primaryGreenLight = '#d3e6b2'
export const primaryGreenVeryLight = '#f4f9ec'

export const primaryOrange = '#f2711c'
export const primaryOrangeHover = ''

export const primaryBlue = '#5b9ad5'
export const primaryBlueHover = ''

export const errorRed = '#9f3a38'

export const lightGrey = '#d9d9d9'
export const backgroundGrey = '#f3f4f5'
export const white = '#ffffff'
export const betaOrange = '#ca6f1f'
export const confirmedBlue = '#154360'
export const lightBlue = '#85c1e9'
