import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Menu, Icon, Label, Popup } from 'semantic-ui-react'

import BuildYourOwnChartsItem from './BuildYourOwnChartsItem'
import { buildYourOwnChartsRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'

const iconCss = css`
  ${increaseSpecificity(`
    font-size: 1.2em;
    margin-top: -2px;
  `)}
`

type Props = {
  active: string
  isMobile: boolean
  menus: {
    analyzeMenu: boolean
    checkMenu: boolean
    resourceSubmenu: boolean
    searchMenu: boolean
  }
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
}

const BuildYourOwnChartsMenu = ({ active, minimizedSideBar, menus, isMobile }: Props) => {
  const { applicationStore } = useStores()
  const location = useLocation()
  const route = applicationStore.hasCustomCharts ? routes.buildYourOwnChartsRoute : location.pathname + location.search

  const handleClick = () => {
    !applicationStore.hasCustomCharts && applicationStore.setModal({ open: true, version: '' })
  }

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.buildYourOwnCharts" />
            </Menu.Header>
            <BuildYourOwnChartsItem
              menus={menus}
              minimizedSideBar={minimizedSideBar}
              isMobile={isMobile}
              active={active}
            />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <NavLink onClick={handleClick} to={route} style={{ fontWeight: 'bold' }}>
            <Menu.Header
              className={cx(
                menuHeaderCss(isMobile, minimizedSideBar),
                buildYourOwnChartsRoutes.indexOf(active) > -1 ? 'active' : '',
              )}
              onClick={handleClick}
            >
              <Icon className={iconCss} name="grid layout" />
              {minimizedSideBar ? null : (
                <>
                  <Trans id="sidebar.buttons.buildYourOwnCharts" />
                  <Label color="orange" size="mini" style={{ marginLeft: '0.3rem' }}>
                    <Trans id="Beta" />
                  </Label>
                </>
              )}
            </Menu.Header>
          </NavLink>
        }
      />
    </>
  )
}

export default BuildYourOwnChartsMenu
