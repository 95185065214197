import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { Menu, Icon, Popup } from 'semantic-ui-react'

import InsightsItems from './InsightsItems'
import { checkRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const InsightsMenu = ({
  active,
  onNavLinkClick,
  minimizedSideBar,
  isSmallHeight,
  menus,
  setShowMenus,
  isMobile,
}: MenuProps) => {
  const { applicationStore } = useStores()

  const handleClick = () => {
    if (isSmallHeight && !minimizedSideBar) {
      setShowMenus((prevState) => ({
        analyzeMenu: false,
        searchMenu: false,
        checkMenu: !prevState.checkMenu,
        resourceSubmenu: false,
      }))
    } else {
      if (applicationStore.hasFilingCheck) onNavLinkClick(routes.filingCheckRoute)
      else applicationStore.setModal({ open: true, version: '' })
    }
  }

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.check" />
            </Menu.Header>
            <InsightsItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <Menu.Header
            className={cx(menuHeaderCss(isMobile, minimizedSideBar), checkRoutes.indexOf(active) > -1 ? 'active' : '')}
            onClick={handleClick}
          >
            <Icon name="map signs" />
            {minimizedSideBar ? null : <Trans id="sidebar.buttons.check" />}
          </Menu.Header>
        }
      />

      {minimizedSideBar ? null : (
        <InsightsItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
      )}
    </>
  )
}

export default InsightsMenu
