import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { FilterType } from '@pp/common/components/filters/filter.store'
import { arrayFromString } from '@pp/common/helpers/utils'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { GrantStatus, Route } from '@pp/modules/search/advanced-search/tabs/filings/filings-search.store'
import {
  RadiusUnit,
  RadiusValue,
  FLimitingClauseType,
} from '@pp/modules/search/advanced-search/typescript/search.interface'
import { RootStoreInterface } from '@pp/store/root.store'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import { WhitespotDrilldownResult, WhitespotResult } from './common/whitespot.type'

export type SearchFilter = FilterType & {
  applicant_location_distance?: number[]
  applicant_location_distance_unit?: string[]
  applicant_location_lat?: number[]
  applicant_location_lon?: number[]
  applicant_location_name?: string[]
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  rep_location_distance?: number[]
  rep_location_distance_unit?: string[]
  rep_location_lat?: number[]
  rep_location_lon?: number[]
  rep_location_name?: string[]
  target_rep_country: string[]
  target_rep_url: string[]
}

export interface SearchParameters {
  [key: string]: string | string[] | number | RadiusUnit | boolean | undefined
  dateType: string
  entityCountry: string[]
  entityLocationLat: number
  entityLocationLng: number
  entityLocationName: string
  entityLocationRadius: number
  entityLocationRadiusUnit: RadiusUnit
  filingGranted: boolean
  filingIPC: string[]
  filingLegalState: string[]
  filingReceivedFromCountry: string[]
  filingReceivedFromLocationLat: number
  filingReceivedFromLocationLng: number
  filingReceivedFromLocationName: string
  filingReceivedFromLocationRadius: number
  filingReceivedFromLocationRadiusUnit: RadiusUnit
  filingReceivedFromPublAuth: string[]
  filingSentToCountry: string[]
  filingSentToLocationLat: number
  filingSentToLocationLng: number
  filingSentToLocationName: string
  filingSentToLocationRadius: number
  filingSentToLocationRadiusUnit: RadiusUnit
  filingSentToPublnAuth: string[]
  filingSequence: string[]
  filingTechnField: string[]
  filingViaPCT: boolean
  primaryAttributeAuthority: string[]
  primaryAttributeCountry: string[]
  primaryAttributeLocationLat: number
  primaryAttributeLocationLng: number
  primaryAttributeLocationName: string
  primaryAttributeLocationRadius: number
  primaryAttributeLocationRadiusUnit: RadiusUnit
  receivedCasesApplicantDecision: boolean
  relevantCountFrom: number
  relevantCountTo?: number
  representativeType: string
  secondLevelCasesApplicantDecision: boolean
  sentCasesApplicantDecision: boolean
  yearFrom: number
  yearTo: number
}

const initialSearchParameters: SearchParameters = {
  entityLocationLat: 38.9071923,
  entityLocationLng: -77.03687070000001,
  entityLocationName: 'Washington, D.C.',
  entityLocationRadius: RadiusValue._20,
  entityLocationRadiusUnit: RadiusUnit.MI,
  entityCountry: [],
  primaryAttributeAuthority: [],
  primaryAttributeCountry: [],
  primaryAttributeLocationLat: 38.9071923,
  primaryAttributeLocationLng: -77.03687070000001,
  primaryAttributeLocationName: 'Washington, D.C.',
  primaryAttributeLocationRadius: RadiusValue._20,
  primaryAttributeLocationRadiusUnit: RadiusUnit.MI,
  dateType: 'm',
  relevantCountFrom: 0,
  relevantCountTo: undefined,
  yearFrom: new Date().getFullYear() - 3,
  yearTo: new Date().getFullYear(),
  filingIPC: [],
  filingTechnField: [],
  filingSequence: [],
  filingGranted: true,
  filingViaPCT: true,
  filingLegalState: [],
  filingReceivedFromCountry: [],
  filingReceivedFromPublAuth: [],
  filingReceivedFromLocationLat: 38.9071923,
  filingReceivedFromLocationLng: -77.03687070000001,
  filingReceivedFromLocationName: 'Washington, D.C.',
  filingReceivedFromLocationRadius: RadiusValue._20,
  filingReceivedFromLocationRadiusUnit: RadiusUnit.MI,
  filingSentToCountry: [],
  filingSentToPublnAuth: [],
  filingSentToLocationLat: 38.9071923,
  filingSentToLocationLng: -77.03687070000001,
  filingSentToLocationName: 'Washington, D.C.',
  filingSentToLocationRadius: RadiusValue._20,
  filingSentToLocationRadiusUnit: RadiusUnit.MI,
  receivedCasesApplicantDecision: true,
  sentCasesApplicantDecision: true,
  secondLevelCasesApplicantDecision: true,
  representativeType: 'internal',
}

export interface WhitespotSearchInterface {
  focalFirms: EntitySearchResult[]
  ipc8Results: WhitespotDrilldownResult[] | null
  limitingClauses: FLimitingClauseType[]
  results: WhitespotResult[] | null
  searchParameters: SearchParameters
  showIpc8Results: boolean
  showStandardizedValues: boolean
  showTargetClients: boolean
  targetFirms: EntitySearchResult[]
}

export class WhitespotAgentSearchStore implements WhitespotSearchInterface {
  private rootStore: RootStoreInterface

  focalFirms: EntitySearchResult[] = []
  targetFirms: EntitySearchResult[] = []
  searchParameters = initialSearchParameters
  limitingClauses: FLimitingClauseType[] = []
  results: WhitespotResult[] | null = null
  ipc8Results: WhitespotDrilldownResult[] | null = null
  showIpc8Results: boolean
  showStandardizedValues: boolean
  showTargetClients: boolean

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    this.showIpc8Results = localStorage.getItem('showIpc8LFResults') === 'true' || false
    this.showStandardizedValues = localStorage.getItem('showLFStandardizedValues') === 'true' || false
    this.showTargetClients = localStorage.getItem('showLFTargetClients') === 'true' || false

    makeAutoObservable(this)
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => {
    this.focalFirms = focalFirms
  }

  setTargetFirms = (targetFirms: EntitySearchResult[]) => {
    this.targetFirms = targetFirms
  }

  setResults = (results: WhitespotResult[]) => {
    this.results = results
  }

  setIpc8Results = (ipc8Results: WhitespotDrilldownResult[]) => {
    this.ipc8Results = ipc8Results
  }

  setShowIpc8Results = (showIpc8Results: boolean) => {
    this.showIpc8Results = showIpc8Results
    localStorage.setItem('showIpc8LFResults', '' + showIpc8Results)
    this.updateSearchParamsFromFilters(this.serverFilters as SearchFilter)
  }

  setShowStandardizedValues = (showStandardizedValues: boolean) => {
    this.showStandardizedValues = showStandardizedValues
    localStorage.setItem('showLFStandardizedValues', '' + showStandardizedValues)
    this.updateSearchParamsFromFilters(this.serverFilters as SearchFilter)
  }

  setShowTargetClients = (showTargetClients: boolean) => {
    this.showTargetClients = showTargetClients
    localStorage.setItem('showLFTargetClients', '' + showTargetClients)
    this.updateSearchParamsFromFilters(this.serverFilters as SearchFilter)
  }

  setSearchParameter = (searchParameter: {}) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: string) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter]
  }

  toggleLimitingClause = (clause: FLimitingClauseType) => {
    const index = this.limitingClauses.indexOf(clause)
    if (index !== -1) {
      this.limitingClauses.splice(index, 1)
    } else {
      this.limitingClauses.push(clause)
    }
  }

  resetSearchUI = () => {
    this.results = null
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  get serverFilters() {
    const filter: SearchFilter = {
      rep_url: this.focalFirms.map((focalFirm) => focalFirm.value),
      rep_country: this.focalFirms.map((focalFirm) => focalFirm.country),
      target_rep_url: this.targetFirms.map((targetFirm) => targetFirm.value),
      target_rep_country: this.targetFirms.map((targetFirm) => targetFirm.country),
      date_from: [this.searchParameters.yearFrom.toString()],
      date_to: [this.searchParameters.yearTo.toString()],
      date_type: [this.searchParameters.dateType.toUpperCase() as DateType],
    }

    // IPCs
    filter.ipc_1 = [] as string[]
    filter.ipc_3 = [] as string[]
    filter.ipc_4 = [] as string[]
    filter.ipc_8 = [] as string[]
    for (const entry of this.searchParameters.filingIPC) {
      if (entry?.length === 1) {
        filter.ipc_1.push(entry)
      } else if (entry?.length === 3) {
        filter.ipc_3.push(entry)
      } else if (entry?.length === 4) {
        filter.ipc_4.push(entry)
      } else {
        filter.ipc_8.push(entry)
      }
    }

    // Technical field
    if (this.limitingClauses.includes(FLimitingClauseType.TechnField)) {
      let technicalFields: string[] = []
      for (const element of this.searchParameters.filingTechnField) {
        if (Number.isNaN(Number(element))) {
          const elements = element.split(',')
          technicalFields = [...technicalFields, ...elements]
        } else {
          technicalFields.push(element)
        }
      }
      filter.techn_field_nr = technicalFields
    }

    // Grant Status
    filter.grant_status = this.limitingClauses.includes(FLimitingClauseType.GrantStatus)
      ? [this.searchParameters.filingGranted ? GrantStatus.GRANTED : GrantStatus.NOT_GRANTED]
      : []

    // Route
    filter.route = this.limitingClauses.includes(FLimitingClauseType.Route)
      ? [this.searchParameters.filingViaPCT ? Route.PCT : Route.DIR]
      : []

    return _.omitBy(filter, _.isEmpty)
  }

  updateSearchParamsFromFilters = (filters: SearchFilter) => {
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]
    searchStrings.push(`showIpc8Results=${this.showIpc8Results}`)
    searchStrings.push(`showStandardizedValues=${this.showStandardizedValues}`)
    searchStrings.push(`showTargetClients=${this.showTargetClients}`)

    if (this.limitingClauses.length > 0) {
      searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      } else {
        searchStrings.push(`${key}=${value}`)
      }
    })

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?${searchStrings.join('&')}`)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const showIpc8Results = urlSearchParams.get('showIpc8Results')
    if (showIpc8Results) {
      this.showIpc8Results = urlSearchParams.get('showIpc8Results') === 'true' || false
    }

    const showStandardizedValues = urlSearchParams.get('showStandardizedValues')
    if (showStandardizedValues) {
      this.showStandardizedValues = urlSearchParams.get('showStandardizedValues') === 'true' || false
    }

    const showTargetClients = urlSearchParams.get('showTargetClients')
    if (showTargetClients) {
      this.showTargetClients = urlSearchParams.get('showTargetClients') === 'true' || false
    }

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) {
      const splitLimitingClauses = limitingClauses.split(',')
      this.limitingClauses = splitLimitingClauses as FLimitingClauseType[]
    }

    this.searchParameters.yearFrom = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.yearFrom

    this.searchParameters.yearTo = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.yearTo

    this.searchParameters.dateType = urlSearchParams.get('date_type') || initialSearchParameters.dateType

    // IPCs
    if (this.limitingClauses.includes(FLimitingClauseType.IPC)) {
      const ipc1 = arrayFromString(urlSearchParams.get('ipc_1'))
      const ipc3 = arrayFromString(urlSearchParams.get('ipc_3'))
      const ipc4 = arrayFromString(urlSearchParams.get('ipc_4'))
      const ipc8 = arrayFromString(urlSearchParams.get('ipc_8'))

      this.searchParameters.filingIPC = [...ipc1, ...ipc3, ...ipc4, ...ipc8]
    }

    // Technical field
    if (this.limitingClauses.includes(FLimitingClauseType.TechnField)) {
      this.searchParameters.filingTechnField =
        arrayFromString(urlSearchParams.get('techn_field_nr')) || initialSearchParameters.filingTechnField
    }

    // Grant Status
    if (this.limitingClauses.includes(FLimitingClauseType.GrantStatus)) {
      this.searchParameters.filingGranted =
        urlSearchParams.get('grant_status') && urlSearchParams.get('grant_status') === GrantStatus.GRANTED
          ? true
          : false
    }

    // Route
    if (this.limitingClauses.includes(FLimitingClauseType.Route)) {
      this.searchParameters.filingViaPCT =
        urlSearchParams.get('route') && urlSearchParams.get('route') === Route.PCT ? true : false
    }
  }
}
