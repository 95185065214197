import React from 'react'

import { css, cx } from '@emotion/css'
import { printCss } from '@pp/common/chart/config/charts-config'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { Popup, Icon } from 'semantic-ui-react'

export const tooltipIconCss = css`
  ${increaseSpecificity(`
  font-size: 0.9em;
  margin-right: 5px;
`)}
`

const Tooltip = ({ testId, tooltip }: { testId: string; tooltip: string }) => {
  return (
    <Popup
      hoverable
      position="top center"
      trigger={
        <Icon
          className={cx(tooltipIconCss, printCss)}
          name="question circle outline"
          data-test-id={`${testId}-tooltip-icon`}
        />
      }
      data-test-id={`${testId}-tooltip`}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: tooltip,
        }}
      />
    </Popup>
  )
}

export default Tooltip
