import { Dispatch, SetStateAction } from 'react'

import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { Menu, Icon, Popup } from 'semantic-ui-react'

import AnalyzeItems from './AnalyzeItems'
import { analyzeRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'
import { Menus } from '../VerticalMenus'

const iconCss = css`
  ${increaseSpecificity(`
    font-size: 1.2em;
    margin-top: -2px;
  `)}
`

export type MenuProps = {
  active: string
  isMobile: boolean
  isSmallHeight: boolean
  menus: Menus
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
  setShowMenus: Dispatch<
    SetStateAction<{ analyzeMenu: boolean; checkMenu: boolean; resourceSubmenu: boolean; searchMenu: boolean }>
  >
}

const AnalyzeMenu = ({
  active,
  onNavLinkClick,
  minimizedSideBar,
  isSmallHeight,
  menus,
  setShowMenus,
  isMobile,
}: MenuProps) => {
  const handleClick = () => {
    if (isSmallHeight && !minimizedSideBar) {
      setShowMenus((prevState) => ({
        analyzeMenu: !prevState.analyzeMenu,
        searchMenu: false,
        checkMenu: false,
        resourceSubmenu: false,
      }))
    } else onNavLinkClick(routes.agentsRoute)
  }

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.analyze" />
            </Menu.Header>
            <AnalyzeItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <Menu.Header
            className={cx(
              menuHeaderCss(isMobile, minimizedSideBar),
              analyzeRoutes.indexOf(active) > -1 ? 'active' : '',
            )}
            onClick={handleClick}
          >
            <Icon className={iconCss} name="chart bar" />
            {minimizedSideBar ? null : <Trans id="sidebar.buttons.analyze" />}
          </Menu.Header>
        }
      />

      {minimizedSideBar ? null : (
        <AnalyzeItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
      )}
    </>
  )
}

export default observer(AnalyzeMenu)
