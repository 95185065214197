import { CSSProperties } from 'react'

import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { primaryGreen } from '@pp/common/css/colors'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import { disabledStyleCss, slideOutCss, submenuCss } from '../../../sidebar.css'
import { Menus } from '../VerticalMenus'

const activeStyle: CSSProperties = {
  fontWeight: 'bold',
  color: primaryGreen,
}

export type DefaultMenuItemsProps = {
  active: string
  isMobile: boolean
  menus: Menus
  minimizedSideBar: boolean
}

const AnalyzeItems = ({ menus, minimizedSideBar, isMobile, active }: DefaultMenuItemsProps) => {
  const { applicationStore, filterStore } = useStores()

  return (
    <Menu.Menu
      className={cx(submenuCss(false, isMobile, minimizedSideBar), !minimizedSideBar && slideOutCss(menus.analyzeMenu))}
    >
      <Menu.Item style={active === routes.agentsRoute ? activeStyle : {}}>
        <NavLink
          className={active.includes(routes.agentsRoute) ? 'active' : ''}
          to={`${routes.agentsRoute}?${filterStore.getActiveFiltersForUrl()}`}
        >
          <Trans id="sidebar.menu.law-firms" />
        </NavLink>
      </Menu.Item>
      <Menu.Item style={active === routes.applicantsRoute ? activeStyle : {}}>
        {applicationStore.hasApplicants ? (
          <NavLink
            className={active.includes(routes.applicantsRoute) ? 'active' : ''}
            to={`${routes.applicantsRoute}?${filterStore.getActiveFiltersForUrl()}`}
          >
            <Trans id="sidebar.menu.applicants" />
          </NavLink>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.applicants" />
          </div>
        )}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(AnalyzeItems)
