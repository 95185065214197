import React from 'react'

import { css } from '@emotion/css'
import { betaOrange, white } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { Header, Label } from 'semantic-ui-react'

import { DropDown, SaveToDashboard, Tooltip } from '.'
import { ExportChartType } from '../ChartHolder'

const headerCss = css`
  ${increaseSpecificity(`
  display: flex;
  justify-content: center;
  margin: 1em 0;
  `)}
`

const titleCss = css`
  margin-right: 5px;
`

const betaLabelCss = css`
  ${increaseSpecificity(`
  background-color: ${betaOrange};
  color: ${white};
  margin-left: 1em;
  margin-top: 0.5em;
  height: fit-content;
`)}
`

type Props = {
  chartType?: string
  isMobile: boolean
  jsxElements: { datesDropDown?: JSX.Element; fieldsDropDown?: JSX.Element; mobileWarning?: JSX.Element | null }
  onExport?: (payload: ExportChartType) => void
  permissions: {
    hasDashboard: boolean
    hasExport: boolean | ExportChartType[]
    isBeta?: boolean
    isPdfExport: boolean
  }
  testId: string
  title: string
  tooltip?: string
}

const ChartHeader = ({ chartType, isMobile, jsxElements, onExport, permissions, testId, title, tooltip }: Props) => {
  const { hasDashboard, hasExport, isBeta, isPdfExport } = permissions
  const { fieldsDropDown, datesDropDown, mobileWarning } = jsxElements
  return (
    <Header textAlign="center" as="h3" className={headerCss}>
      <div className={titleCss} dangerouslySetInnerHTML={{ __html: title }}></div>
      {tooltip && !isPdfExport ? <Tooltip testId={testId} tooltip={tooltip} /> : null}
      {hasDashboard && !isPdfExport ? <SaveToDashboard chartType={chartType} testId={testId} title={title} /> : null}
      {fieldsDropDown ? fieldsDropDown : null}
      {datesDropDown ? datesDropDown : null}
      {mobileWarning ? mobileWarning : null}
      {hasExport && !isPdfExport ? (
        <DropDown hasExport={hasExport} onExport={onExport} testId={testId} isMobile={isMobile} />
      ) : null}
      {isBeta ? (
        <Label className={betaLabelCss} size="mini">
          Beta
        </Label>
      ) : null}
    </Header>
  )
}

export default ChartHeader
