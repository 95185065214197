import { Trans } from '@lingui/react'
import { Dropdown, Icon } from 'semantic-ui-react'

import { ExportChartType } from '../ChartHolder'

const ExportTypeToHtmlItem = (callback: (type: ExportChartType) => () => void, hasExportPermissions: boolean) => {
  return (type: ExportChartType) => {
    switch (type) {
      case ExportChartType.JPEG:
        return (
          <Dropdown.Item
            key={'jpg'}
            disabled={!hasExportPermissions}
            onClick={callback(ExportChartType.JPEG)}
            data-test-id="jpg-item"
          >
            <Trans id="profile.card.export.dropdown.jpg" />
            {!hasExportPermissions && (
              <span>
                &nbsp;
                <Icon name="lock" />
              </span>
            )}
          </Dropdown.Item>
        )

      case ExportChartType.SVG:
        return (
          <Dropdown.Item
            key={'svg'}
            disabled={!hasExportPermissions}
            onClick={callback(ExportChartType.SVG)}
            data-test-id="svg-item"
          >
            <Trans id="profile.card.export.dropdown.svg" />
            {!hasExportPermissions && (
              <span>
                &nbsp;
                <Icon name="lock" />
              </span>
            )}
          </Dropdown.Item>
        )

      case ExportChartType.PDF:
        return (
          <Dropdown.Item
            key={'pdf'}
            disabled={!hasExportPermissions}
            onClick={callback(ExportChartType.PDF)}
            data-test-id="pdf-item"
          >
            <Trans id="profile.card.export.dropdown.pdf" />
            {!hasExportPermissions && (
              <span>
                &nbsp;
                <Icon name="lock" />
              </span>
            )}
          </Dropdown.Item>
        )

      case ExportChartType.XLSX:
        return (
          <Dropdown.Item
            key={'xlsx'}
            disabled={!hasExportPermissions}
            onClick={callback(ExportChartType.XLSX)}
            data-test-id="xslx-item"
          >
            <Trans id="profile.card.export.dropdown.xlsx" />
            {!hasExportPermissions && (
              <span>
                &nbsp;
                <Icon name="lock" />
              </span>
            )}
          </Dropdown.Item>
        )
    }
  }
}

export default ExportTypeToHtmlItem
