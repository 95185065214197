import { css } from '@emotion/css'
import { increaseSpecificity } from '@pp/common/css/css.helper'

export const modalCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  ${isMobile && 'width: 80%;'}
`)}
`

export const gridCss = css`
  ${increaseSpecificity(`min-height: 500px;`)}
`

export const messageCss = (isMobile: boolean) => css`
  width: 70% !important;
  margin: auto !important;
  ${isMobile ? 'margin-top: 30px !important' : 'margin-top: 130px !important'};
  background-color: transparent !important;
  padding: 0 !important;
  ${!isMobile && 'box-shadow: -15px 0px 17px -7px rgba(0, 0, 0, 0.75) !important'};
  ${isMobile && 'box-shadow: 15px 0 17px -7px rgba(0, 0, 0, 0.75), -15px 0 17px -7px rgba(0, 0, 0, 0.75) !important'}
`

export const rowCss = css`
  padding: 24px;
  height: 100%;
  color: black;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const logoCss = css`
  ${increaseSpecificity(`
  width: 40%;
  margin-bottom: 15px;
`)}
`

export const listCss = css`
  padding: 0;
  margin: 0;
  font-size: 16px;
`

export const listItemCss = css`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
`

export const itemIconCss = css`
  width: 25px;
  height: 25px;
  margin-right: 25px;
`

export const imageCss = css`
  margin: 20px 35px 40px;
`

export const titleCss = (isMobile: boolean) => css`
  font-size: ${isMobile ? '15px' : '20px'};
  text-align: center;
`

export const flexCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const leftColumnCss = (isMobile: boolean) => css`
  background-color: #ffffff;
  border-radius: ${isMobile ? '5px 5px 0 0' : '5px 0 0 5px'};
`

export const rightColorCss = (isMobile: boolean) => css`
  background-color: #d9d9d9;
  border-radius: ${isMobile ? '0 0 5px 5px' : '0 5px 5px 0'};
`

export const learnMoreCss = (isMobile: boolean) => css`
  ${isMobile && 'margin-top: 5%'};
  text-align: center;
  font-size: 16px;
`

export const loginTextCss = (isMobile: boolean) => css`
  text-align: center;
  font-size: ${isMobile ? '16px' : '20px'};
  ${isMobile && 'margin-top: 8%; margin-bottom: 2%'};
`

export const buttonCss = css`
  ${increaseSpecificity(`
  margin: 10px;
`)}
`
