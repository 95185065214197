import { useAuth0 } from '@auth0/auth0-react'
import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/macro'
import { t } from '@lingui/macro'
import Logo from '@pp/assets/ip-logo.svg'
import UpgradeImage from '@pp/assets/upgradeModalImage.webp'
import { lightGrey, white } from '@pp/common/css/colors'
import { increaseSpecificity, mobileMediaQuery } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { Grid, Modal, Image, Button, Icon } from 'semantic-ui-react'

import { modalCss, gridCss, rowCss, flexCss, logoCss, imageCss } from '../buy-modal/buy-modal.css'

const buttonCss = css`
  ${increaseSpecificity(`
  margin: 10px;
`)}
`

const closeButtonCss = css`
  ${increaseSpecificity(`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  `)}
`

const rowFlexCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const centerCss = css`
  justify-content: center;
`

const leftColorCss = (isMobile: boolean) => css`
  background-color: ${lightGrey};
  border-radius: ${isMobile ? '5px 5px 0 0' : '5px 0 0 5px'};
`

const rightColorCss = (isMobile: boolean) => css`
  background-color: ${white};
  border-radius: ${isMobile ? '0 0 5px 5px' : '0 5px 5px 0'};
`

const upgradeTextCss = (isMobile: boolean) => css`
  font-size: ${isMobile ? '16px' : '20px'};
`

const featureTextCss = (isMobile: boolean) => css`
  font-size: ${isMobile ? '16px' : '20px'};
  ${isMobile && 'margin-top: 7%'};
  margin-bottom: 5%;
  text-align: center;
`

const learnMoreCss = (isMobile: boolean) => css`
  ${isMobile && 'text-align: center;'};
  font-size: ${isMobile ? '12px' : '16px'};
`

type UpgradeModalProps = { open: boolean }

function UpgradeModal({ open }: UpgradeModalProps) {
  const { applicationStore } = useStores()
  const isMobile = useMediaQuery(mobileMediaQuery)
  const { user } = useAuth0()
  const versions = user?.['https://app.ip-pilot.com/versions']
  const isTrial = versions && versions.length > 0 && versions.includes('Trial')

  const onModalClose = () => {
    document.body.className = document.body.className
      .split(' ')
      .filter((bodyClass) => bodyClass !== 'body-scrollable')
      .join(' ')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    applicationStore.setModal({ open: false, version: '' })
  }

  return (
    <Modal open={open} dimmer className={modalCss(isMobile)} onClose={onModalClose}>
      {isMobile && (
        <Button
          primary
          onClick={() => applicationStore.setModal({ open: false, version: '' })}
          size="medium"
          className={closeButtonCss}
          icon={<Icon name="close" />}
        />
      )}
      <Grid className={gridCss}>
        <Grid.Column mobile={16} tablet={7} computer={7} className={leftColorCss(isMobile)}>
          <Grid.Row className={rowCss}>
            <div className={flexCss}>
              <Image src={Logo} className={logoCss} alt="logo" />
              {!isTrial && (
                <div className={rowFlexCss}>
                  <Icon name="lock"></Icon>
                  <span className={upgradeTextCss(isMobile)}>
                    <Trans id="upgradeModal.title" />
                  </span>
                </div>
              )}
            </div>
            <div className={flexCss}>
              <span className={featureTextCss(isMobile)}>
                {isTrial ? (
                  <p>This feature is not available during trial.</p>
                ) : (
                  <p>
                    This feature is not included <br></br> in your subscription.
                  </p>
                )}
              </span>
              {!isTrial && (
                <Button
                  className={buttonCss}
                  primary
                  size="big"
                  onClick={() => window.open('https://www.ip-pilot.com/en/get-access/trial/', '_blank')}
                >
                  <Trans id="upgradeModal.trialAccess.button" />
                </Button>
              )}
            </div>

            <div
              className={learnMoreCss(isMobile)}
              dangerouslySetInnerHTML={{
                __html: t`buyModal.learnMore.text`,
              }}
            />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={9} computer={9} className={rightColorCss(isMobile)}>
          <Grid.Row className={cx(rowCss, centerCss)}>
            <div className={imageCss}>
              <Image src={UpgradeImage} alt="upgrade-image" />
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Modal>
  )
}

export default UpgradeModal
