import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu, Icon, Popup } from 'semantic-ui-react'

import DashboardItem from './DashboardItem'
import { dashboardRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'

const iconCss = css`
  ${increaseSpecificity(`
    font-size: 1.2em;
    margin-top: -2px;
  `)}
`

type Props = {
  active: string
  isMobile: boolean
  menus: {
    analyzeMenu: boolean
    checkMenu: boolean
    resourceSubmenu: boolean
    searchMenu: boolean
  }
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
}

const DashboardMenu = ({ active, minimizedSideBar, menus, isMobile }: Props) => {
  const { applicationStore } = useStores()
  const location = useLocation()
  const route = applicationStore.hasDashboards ? routes.dashboardsRoute : location.pathname + location.search

  const handleClick = () => !applicationStore.hasDashboards && applicationStore.setModal({ open: true, version: '' })

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.dashboard" />
            </Menu.Header>
            <DashboardItem menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <NavLink onClick={handleClick} to={route} style={{ fontWeight: 'bold' }}>
            <Menu.Header
              className={cx(
                menuHeaderCss(isMobile, minimizedSideBar),
                dashboardRoutes.indexOf(active) > -1 ? 'active' : '',
              )}
            >
              <Icon className={iconCss} name="grid layout" />
              {minimizedSideBar ? null : <Trans id="sidebar.buttons.dashboard" />}
            </Menu.Header>
          </NavLink>
        }
      />
    </>
  )
}

export default DashboardMenu
