import { ReactNode } from 'react'

import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { BuilderPurpose, FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import * as _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import { ValidationsQueryState } from './constants/query-state-machine-validations.const'
import { SearchFilter, ValidationsSearchStoreInterface } from './validations-search.type'
import { UIState } from '../../constants/common-options.const'
import { FLimitingClauseType, Result, VLimitingClauseType, VSearchParameters } from '../../typescript/search.interface'
import { LocationQueryState } from '../filings/constants/query-state-machine-filings.const'
import { NumberedResult } from '../filings/filings-search.store'

enum ValidationType {
  Inbound = 'INBOUND',
  Internal = 'INTERNAL',
  Outbound = 'OUTBOUND',
}

const initialSearchParameters: VSearchParameters = {
  entityCountry: [],
  primaryAttributeCountry: [],
  primaryAttributeAuthority: [],
  yearFrom: new Date().getFullYear() - 3,
  yearTo: new Date().getFullYear(),
  dateType: 'G',
  representativeType: 'internal',
  lawfirmRepresentedEPFiling: false,
  validationLawFirmCountry: [],
  receivingLawFirmChosenBy: 'lf',
  validationServiceProvider: [],
  applicantCountry: [],
  relevant_count_from: [],
  relevant_count_to: [],
  selectedCharts: [],
  val_appln_auth: [],
  val_inhouse: [],
  ep_val_type: [],
  ep_rep_url: [],
  ep_rep_country: [],
  applicant_url: [],
  applicant_country: [],
  val_decision_maker: [],
  val_service_provider: [],
  val_rep_country: [],
  val_rep_url: [],
  ipc_class: [],
  techn_field_nr: [],
}

const newFilters = [
  { prop: 'applicant_country', value: VLimitingClauseType.ApplicantCountry },
  { prop: 'val_appln_auth', value: VLimitingClauseType.ValidationApplicationAuthority },
  { prop: 'val_inhouse', value: VLimitingClauseType.ValInhouse },
  { prop: 'ep_val_type', value: VLimitingClauseType.EpValType },
  { prop: 'ep_rep_url', value: VLimitingClauseType.EpRepUrl },
  { prop: 'ep_rep_country', value: VLimitingClauseType.EpRepCountry },
  { prop: 'applicant_url', value: VLimitingClauseType.ApplicantUrl },
  { prop: 'applicant_country', value: VLimitingClauseType.EpValApplicantCountry },
  { prop: 'val_decision_maker', value: VLimitingClauseType.ValDecisionMaker },
  { prop: 'val_service_provider', value: VLimitingClauseType.ValServiceProvider },
  { prop: 'val_rep_country', value: VLimitingClauseType.ValRepCountry },
  { prop: 'val_rep_url', value: VLimitingClauseType.ValRepUrl },
  { prop: 'lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'direction', value: FLimitingClauseType.Direction },
  { prop: 'grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'route', value: FLimitingClauseType.Route },
  { prop: 'applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'sequence', value: FLimitingClauseType.Sequence },
  { prop: 'decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'publn_auth', value: 'publn_auth' },
  { prop: 'int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'rep_url', value: FLimitingClauseType.RepUrl },
  { prop: 'dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'techn_field_nr', value: VLimitingClauseType.TechnField },
]

export class ValidationsSearchStore implements ValidationsSearchStoreInterface {
  private rootStore: RootStoreInterface

  results: NumberedResult[] | null = null
  activePage = 1
  uiState = UIState.DisplayResults
  locationQueryState = LocationQueryState.FromCountries
  queryState = ValidationsQueryState.LawFirmsValidateAtEpPublnAuth
  limitingClauses: VLimitingClauseType[] = []
  searchParameters = initialSearchParameters
  selectedCharts: ChartWidgetType[] = []
  confirmed: boolean[] = [true]
  visibleConstraints: ReactNode[] = []

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setLimitingClauses = (clauses: VLimitingClauseType[]) => (this.limitingClauses = clauses)
  setActivePage = (activePage: number) => (this.activePage = activePage)
  setCharts = (charts: ChartWidgetType[]) => (this.selectedCharts = charts)
  setConfirmed = (confirmed: boolean[]) => (this.confirmed = confirmed)

  setQueryState = (queryState: ValidationsQueryState) => {
    this.limitingClauses = []
    this.searchParameters = Object.assign({}, initialSearchParameters, {
      yearFrom: this.searchParameters.yearFrom,
      yearTo: this.searchParameters.yearTo,
      entityCountry: this.searchParameters.entityCountry,
    })
    this.queryState = queryState
  }

  setVisibleConstraints = (visibleConstraint: ReactNode) =>
    (this.visibleConstraints = [...this.visibleConstraints, visibleConstraint])

  setSearchParameter = (searchParameter: {}) =>
    (this.searchParameters = Object.assign({}, this.searchParameters, searchParameter))

  unsetSearchParameter = (searchParameter: string) =>
    (this.searchParameters[searchParameter] = initialSearchParameters[searchParameter])

  toggleLimitingClause = (clause: VLimitingClauseType) => {
    const index = this.limitingClauses.indexOf(clause)
    if (index !== -1) {
      this.limitingClauses.splice(index, 1)
    } else {
      this.limitingClauses.push(clause)
    }
  }

  setResults = (results: Result[] | null) => {
    if (results) {
      this.results = results.map((result: Result, id) => ({
        ...result,
        id: this.leftPad(id + 1, results.length.toString().length),
      }))
    } else {
      this.results = results
    }
  }

  resetSearchUI = () => {
    this.results = null
    this.activePage = 1
    this.locationQueryState = LocationQueryState.FromCountries
    this.queryState = ValidationsQueryState.LawFirmsValidateAtEpPublnAuth
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters
    this.selectedCharts = []
    this.confirmed = [true]

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  get getCharts() {
    return this.selectedCharts
  }

  get familyType(): FamilyType[] {
    return this.rootStore.applicationStore.familyType
  }

  get serverFilters() {
    const entityType = this.queryState.startsWith('applicants') ? EntityType.Applicant : EntityType.Agent
    const filter: SearchFilter = {
      builder_purpose: [BuilderPurpose.VALIDATION],
      family_type: this.familyType,
      advanced_search_type: [entityType === EntityType.Agent ? 'LF' : 'A'],
      date_from: [this.searchParameters.yearFrom.toString()],
      date_to: [this.searchParameters.yearTo.toString()],
      date_type: [this.searchParameters.dateType],
    }

    newFilters.forEach((el) => (filter[el.prop] = this.searchParameters[el.prop]))

    // Iterating over main query states and constructing filters
    switch (this.queryState) {
      case ValidationsQueryState.ApplicantsValidateAtEpPublnAuth:
        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.val_appln_auth = this.searchParameters.primaryAttributeAuthority
        if (this.searchParameters.ep_val_type.length === 0)
          filter.ep_val_type = [ValidationType.Inbound, ValidationType.Internal]

        if (this.searchParameters.entityCountry.length > 0)
          filter.applicant_country = this.searchParameters.entityCountry
        if (this.searchParameters.entityCountry.length > 0)
          filter.val_rep_country = this.limitingClauses.includes(VLimitingClauseType.ValidatedByLawfirmFromCountry)
            ? this.searchParameters.validationLawFirmCountry
            : []

        filter.val_inhouse = this.limitingClauses.includes(VLimitingClauseType.RepresentativeType)
          ? [this.searchParameters.representativeType.toUpperCase() === 'INTERNAL' ? 1 : 0]
          : []

        // Confirmed
        filter.confirmed = [true, false]
        break
      case ValidationsQueryState.LawFirmsValidateAtEpPublnAuth:
        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.val_appln_auth = this.searchParameters.primaryAttributeAuthority
        if (this.searchParameters.ep_val_type.length === 0)
          filter.ep_val_type = [ValidationType.Inbound, ValidationType.Internal]

        if (this.searchParameters.entityCountry.length > 0) filter.val_rep_country = this.searchParameters.entityCountry

        if (this.searchParameters.applicant_country.length === 0)
          filter.applicant_country = this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)
            ? this.searchParameters.applicantCountry
            : []

        if (this.searchParameters.ep_val_type.length === 0)
          filter.ep_val_type = this.limitingClauses.includes(VLimitingClauseType.LawfirmRepresentedEPFiling)
            ? [this.searchParameters.lawfirmRepresentedEPFiling ? ValidationType.Internal : ValidationType.Inbound]
            : [ValidationType.Inbound, ValidationType.Internal]

        // Confirmed
        filter.confirmed = [true, false]
        break
      case ValidationsQueryState.LawfirmsReceiveFromEpCountry:
        if (this.searchParameters.ep_val_type.length === 0) filter.ep_val_type = [ValidationType.Inbound]
        if (this.searchParameters.primaryAttributeCountry.length > 0)
          filter.ep_rep_country = this.searchParameters.primaryAttributeCountry
        if (this.searchParameters.entityCountry.length > 0) filter.val_rep_country = this.searchParameters.entityCountry

        if (this.searchParameters.applicant_country.length === 0)
          filter.applicant_country = this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)
            ? this.searchParameters.applicantCountry
            : []

        // Confirmed
        filter.confirmed = [true, false]
        break
      case ValidationsQueryState.LawfirmsSendToEpCountry:
        if (this.searchParameters.ep_val_type.length === 0) filter.ep_val_type = [ValidationType.Outbound]
        if (this.searchParameters.entityCountry.length > 0) filter.ep_rep_country = this.searchParameters.entityCountry
        if (this.searchParameters.primaryAttributeCountry.length > 0)
          filter.val_rep_country = this.searchParameters.primaryAttributeCountry

        if (this.searchParameters.applicant_country.length === 0)
          filter.applicant_country = this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)
            ? this.searchParameters.applicantCountry
            : []

        // Confirmed
        filter.confirmed = [true, false]
        break
      case ValidationsQueryState.LawfirmsSendToEpPublnAuth:
        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.val_appln_auth = this.searchParameters.primaryAttributeAuthority
        if (this.searchParameters.ep_val_type.length === 0) filter.ep_val_type = [ValidationType.Outbound]
        if (this.searchParameters.entityCountry.length > 0) filter.ep_rep_country = this.searchParameters.entityCountry

        if (this.searchParameters.applicant_country.length === 0)
          filter.applicant_country = this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)
            ? this.searchParameters.applicantCountry
            : []

        // Confirmed
        filter.confirmed = [true, false]
        break
    }

    // Iterating over constraints and constructing additional filters

    // IPCs
    filter.ipc_1 = [] as string[]
    filter.ipc_3 = [] as string[]
    filter.ipc_4 = [] as string[]
    filter.ipc_8 = [] as string[]
    for (const entry of this.searchParameters.ipc_class) {
      if (entry?.length === 1 || entry?.length === 2) {
        filter.ipc_1.push(entry)
      } else if (entry?.length === 3 || (entry.includes('!') && entry?.length === 4)) {
        filter.ipc_3.push(entry)
      } else if (entry?.length === 4 || entry?.length === 5) {
        filter.ipc_4.push(entry)
      } else {
        filter.ipc_8.push(entry)
      }
    }

    // Technical field
    if (this.limitingClauses.includes(VLimitingClauseType.TechnField)) {
      let technicalFields: string[] = []
      for (const element of this.searchParameters.techn_field_nr) {
        if (Number.isNaN(Number(element))) {
          const elements = element.split(',')
          if (element.includes('!')) {
            technicalFields = [...technicalFields, ...elements.map((entry, index) => (index ? `!${entry}` : entry))]
          } else {
            technicalFields = [...technicalFields, ...elements]
          }
        } else {
          technicalFields.push(element)
        }
      }
      filter.techn_field_nr = technicalFields
    }

    // Relevant Count filter
    if (this.limitingClauses.includes(VLimitingClauseType.LimitNumberOfRelevantFilings)) {
      filter.relevant_count_from = this.searchParameters.relevant_count_from?.map((entry) => Number(entry))
      filter.relevant_count_to = this.searchParameters.relevant_count_to?.map((entry) => Number(entry))
    }

    // Selected Charts
    filter.selected_charts = this.selectedCharts

    return _.omitBy<SearchFilter>(filter, _.isEmpty)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)
    const selectedCharts = urlSearchParams.get('selected_charts')
    const splittedCharts = selectedCharts && selectedCharts.split(',')
    const confirmed = urlSearchParams
      .get('confirmed')
      ?.split(',')
      .map((el) => {
        if (el === 'true') return true
        if (el === 'false') return false
        return el
      })

    this.rootStore.applicationStore.setFamilyType(
      (this.arrayFromString(urlSearchParams.get('family_type')) as FamilyType[]) || 'P',
    )

    if (confirmed) this.confirmed = confirmed as boolean[]
    if (selectedCharts) this.selectedCharts = splittedCharts as ChartWidgetType[]

    const queryState = urlSearchParams.get('queryState') as ValidationsQueryState
    if (queryState) this.queryState = queryState

    const limitingClauses = urlSearchParams.get('limitingClauses')
    const splitLimitingClauses = limitingClauses ? limitingClauses.split(',') : []
    this.limitingClauses = splitLimitingClauses as VLimitingClauseType[]

    this.searchParameters.yearFrom = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.yearFrom

    this.searchParameters.yearTo = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.yearTo

    switch (queryState) {
      case ValidationsQueryState.ApplicantsValidateAtEpPublnAuth:
        if (!splitLimitingClauses?.includes('applicant_country')) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.entityCountry
        }

        if (!splitLimitingClauses?.includes('val_appln_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('val_appln_auth')) ||
            initialSearchParameters.primaryAttributeAuthority
        }

        if (this.limitingClauses.includes(VLimitingClauseType.RepresentativeType)) {
          this.searchParameters.representativeType =
            urlSearchParams.get('val_inhouse') !== undefined
              ? urlSearchParams.get('val_inhouse')
                ? 'INTERNAL'
                : 'EXTERNAL'
              : initialSearchParameters.representativeType
        }

        if (this.limitingClauses.includes(VLimitingClauseType.ValidatedByLawfirmFromCountry)) {
          this.searchParameters.validationLawFirmCountry =
            this.arrayFromString(urlSearchParams.get('val_rep_country')) ||
            initialSearchParameters.validationLawFirmCountry
        }

        break
      case ValidationsQueryState.LawFirmsValidateAtEpPublnAuth:
        if (!splitLimitingClauses?.includes('val_appln_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('val_appln_auth')) ||
            initialSearchParameters.primaryAttributeAuthority
        }

        if (!splitLimitingClauses?.includes('val_rep_country')) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('val_rep_country')) || initialSearchParameters.entityCountry
        }

        if (this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)) {
          this.searchParameters.applicantCountry =
            this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.applicantCountry
        }

        if (this.limitingClauses.includes(VLimitingClauseType.LawfirmRepresentedEPFiling)) {
          const validationType = this.arrayFromString(urlSearchParams.get('val_type'))
          this.searchParameters.lawfirmRepresentedEPFiling =
            validationType.length > 0
              ? validationType[0] === ValidationType.Internal
                ? true
                : false
              : initialSearchParameters.lawfirmRepresentedEPFiling
        }
        break
      case ValidationsQueryState.LawfirmsReceiveFromEpCountry:
        if (!splitLimitingClauses?.includes('val_rep_country')) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('val_rep_country')) || initialSearchParameters.entityCountry
        }

        if (!splitLimitingClauses?.includes('ep_rep_country')) {
          this.searchParameters.primaryAttributeCountry =
            this.arrayFromString(urlSearchParams.get('ep_rep_country')) ||
            initialSearchParameters.primaryAttributeCountry
        }

        if (this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)) {
          this.searchParameters.applicantCountry =
            this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.applicantCountry
        }

        break
      case ValidationsQueryState.LawfirmsSendToEpCountry:
        if (!splitLimitingClauses?.includes('ep_rep_country')) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('ep_rep_country')) || initialSearchParameters.entityCountry
        }

        if (!splitLimitingClauses?.includes('val_rep_country')) {
          this.searchParameters.primaryAttributeCountry =
            this.arrayFromString(urlSearchParams.get('val_rep_country')) ||
            initialSearchParameters.primaryAttributeCountry
        }

        if (this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)) {
          this.searchParameters.applicantCountry =
            this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.applicantCountry
        }

        break
      case ValidationsQueryState.LawfirmsSendToEpPublnAuth:
        if (!splitLimitingClauses?.includes('ep_rep_country')) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('ep_rep_country')) || initialSearchParameters.entityCountry
        }

        if (!splitLimitingClauses?.includes('val_appln_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('val_appln_auth')) ||
            initialSearchParameters.primaryAttributeAuthority
        }

        if (this.limitingClauses.includes(VLimitingClauseType.ApplicantCountry)) {
          this.searchParameters.applicantCountry =
            this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.applicantCountry
        }

        break
    }

    // IPCs
    if (this.limitingClauses.includes(VLimitingClauseType.IPC)) {
      const ipc1 = this.arrayFromString(urlSearchParams.get('ipc_1'))
      const ipc3 = this.arrayFromString(urlSearchParams.get('ipc_3'))
      const ipc4 = this.arrayFromString(urlSearchParams.get('ipc_4'))
      const ipc8 = this.arrayFromString(urlSearchParams.get('ipc_8'))

      this.searchParameters.ipc_class = [...ipc1, ...ipc3, ...ipc4, ...ipc8]
    }

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value as VLimitingClauseType)) {
        this.searchParameters[el.prop] =
          this.arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })

    // Relevant Count
    if (this.limitingClauses.includes(VLimitingClauseType.LimitNumberOfRelevantFilings)) {
      this.searchParameters.relevant_count_from =
        this.arrayFromString(urlSearchParams.get('relevant_count_from'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_from
      this.searchParameters.relevant_count_to =
        this.arrayFromString(urlSearchParams.get('relevant_count_to'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_to
    }
  }

  updateSearchParamsFromFilters = (filters: SearchFilter) => {
    const searchStrings: string[] = []
    let reducedFilters: Omit<SearchFilter, 'advanced_search_type' | 'builder_purpose' | 'family_type'> = Object.assign(
      {},
      filters,
      {
        advanced_search_type: null,
        builder_purpose: null,
      },
    )
    reducedFilters = _.omitBy(reducedFilters, _.isNil)

    searchStrings.push(`queryState=${this.queryState}`)
    if (this.limitingClauses.length > 0) {
      searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    }

    Object.entries(reducedFilters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      } else {
        searchStrings.push(`${key}=${value}`)
      }
    })

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?${searchStrings.join('&')}`)
  }

  getSearchParamsFromFilters() {
    const searchStrings: string[] = []

    Object.entries(this.serverFilters).forEach(([key, value]) => {
      if (
        key !== 'advanced_search_type' &&
        key !== 'relevant_count_from' &&
        key !== 'relevant_count_to' &&
        key !== 'builder_purpose' &&
        key !== 'selected_charts'
      ) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      }
    })

    return searchStrings.join('&')
  }

  private arrayFromString(str: string | null): string[] {
    if (str) {
      const splitStr = str.split(',')
      return splitStr
    } else {
      return []
    }
  }

  private leftPad(no: number, targetLength: number) {
    let output = no + ''
    while (output.length < targetLength) {
      output = '0' + output
    }
    return output
  }
}
