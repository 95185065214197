import { cx } from '@emotion/css'
import { mobileMediaQuery, biggerMobileMediaQuery, sidebarHeightBreakpoint } from '@pp/common/css/css.helper'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { Popup } from 'semantic-ui-react'

import { IpButton, PatentButton, SmallProductsButtons, TrademarkButton } from '.'
import { iconCss } from '../sidebar.css'

const PopupSmallProductsButtons = ({ minimizedSideBar }: { minimizedSideBar: boolean }) => {
  const { applicationStore } = useStores()
  const isMobile = useMediaQuery(mobileMediaQuery)
  const isBiggerMobile = useMediaQuery(biggerMobileMediaQuery)
  const isSidebarBreakpoint = useMediaQuery(sidebarHeightBreakpoint)

  const hasFamilyType = (familyType: FamilyType) => applicationStore.familyType?.includes(familyType)

  if (isSidebarBreakpoint || isBiggerMobile)
    return (
      <Popup
        content={
          <SmallProductsButtons
            isMobile={isMobile}
            minimizedSideBar={minimizedSideBar}
            isSidebarBreakpoint={isSidebarBreakpoint}
          />
        }
        // disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <div className={cx(iconCss)}>
            {hasFamilyType('P') && hasFamilyType('T') ? (
              <IpButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
            ) : null}
            {hasFamilyType('P') && !hasFamilyType('T') ? (
              <PatentButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
            ) : null}
            {hasFamilyType('T') && !hasFamilyType('P') ? (
              <TrademarkButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
            ) : null}
          </div>
        }
      />
    )
  return (
    <SmallProductsButtons
      isMobile={isMobile}
      minimizedSideBar={minimizedSideBar}
      isSidebarBreakpoint={isSidebarBreakpoint}
    />
  )
}

export default observer(PopupSmallProductsButtons)
