import { useAuth0 } from '@auth0/auth0-react'
import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { backgroundGrey, primaryGreen, primaryOrange } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu, Popup, Icon } from 'semantic-ui-react'

import { SidebarLanguageSelector } from '.'
import { iconCss, nonAuthenticatedIconCss } from '../sidebar.css'

const bottomIconsCss = css`
  padding: 3px;
`

const bottomItemCss = css`
  ${increaseSpecificity(`
		padding: 5px;
		color: ${primaryGreen};
  	font-size: 1em;
    cursor: pointer;

    a {
      color: ${primaryGreen};
      &:visited {
        color: ${primaryGreen};
      }
    }

		&:before {
				background-color: ${backgroundGrey};
		}
	`)}
`

const orangeIconCss = css`
  ${increaseSpecificity(`
    color: ${primaryOrange};

    &:hover {
      border-bottom: 3px solid ${primaryOrange};
    }
  `)}
`

const menuItemsCss = (flexDirection: boolean) => css`
  display: flex;
  justify-content: center;
  flex-direction: ${flexDirection ? 'column' : 'row'};
`

type Props = {
  active: string
  isMobile: boolean
  isSmallHeight: boolean
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
}

const FooterButtons = ({ active, isMobile, onNavLinkClick, minimizedSideBar, isSmallHeight }: Props) => {
  const { pathname } = useLocation()
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0()

  const onLogoutClick = () => {
    logout({ returnTo: window.location.origin })
    localStorage.removeItem('token')
  }

  const onLoginClick = () => {
    loginWithRedirect({ appState: { returnTo: pathname } })
  }

  const menuItems = () => (
    <div className={menuItemsCss(minimizedSideBar && !isSmallHeight)}>
      <Menu.Item className={bottomItemCss}>
        {isAuthenticated ? (
          <Popup
            size="mini"
            content={<Trans id="sidebar.buttons.tooltip.referral-program" />}
            trigger={
              <div
                className={cx(iconCss, orangeIconCss)}
                onClick={() => window.open('https://www.ip-pilot.com/en/referral-program/', '_blank')}
              >
                <Icon name="gift" />
              </div>
            }
          />
        ) : (
          <div className={nonAuthenticatedIconCss}>
            <Icon name="gift" />
          </div>
        )}
      </Menu.Item>

      <Menu.Item className={bottomItemCss}>
        <SidebarLanguageSelector isMobile={isMobile} />
      </Menu.Item>

      <Menu.Item className={bottomItemCss}>
        {isAuthenticated ? (
          <Popup
            size="mini"
            content={<Trans id="sidebar.buttons.tooltip.settings" />}
            trigger={
              <div className={cx(iconCss, active === routes.settingsRoute ? 'active' : '')}>
                <NavLink to={routes.settingsRoute}>
                  <Icon name="setting" />
                </NavLink>
              </div>
            }
          />
        ) : (
          <div className={nonAuthenticatedIconCss}>
            <Icon name="setting" />
          </div>
        )}
      </Menu.Item>

      <Menu.Item className={bottomItemCss}>
        {isAuthenticated ? (
          <Popup
            size="mini"
            content={<Trans id="sidebar.buttons.tooltip.logout" />}
            trigger={
              <div className={iconCss}>
                <Icon name="power" onClick={onLogoutClick} />
              </div>
            }
          />
        ) : (
          <Popup
            size="mini"
            content={<Trans id="sidebar.buttons.tooltip.login" />}
            trigger={
              <div className={cx(iconCss, orangeIconCss)}>
                <Icon name="sign-in alternate" onClick={onLoginClick} />
              </div>
            }
          />
        )}
      </Menu.Item>
    </div>
  )

  return (
    <div className={bottomIconsCss}>
      {isSmallHeight && minimizedSideBar ? (
        <Popup
          content={menuItems()}
          disabled={!minimizedSideBar}
          position="right center"
          flowing
          hoverable
          trigger={
            <div
              className={cx(iconCss, active === routes.settingsRoute ? 'active' : '')}
              onClick={() => onNavLinkClick(routes.settingsRoute)}
            >
              <Icon name="setting" />
            </div>
          }
        />
      ) : (
        menuItems()
      )}
    </div>
  )
}

export default observer(FooterButtons)
