import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import { backgroundGrey } from '@pp/common/css/colors'
import { Popup, Icon, Menu } from 'semantic-ui-react'

import BuildYourOwnChartsItem from './byoc-menu/BuildYourOwnChartsItem'
import { Menus } from './VerticalMenus'
import { AnalyzeItems, DashboardItem, InsightsItems, ResourcesItems, SearchItems } from '..'

const popupCss = css`
  display: flex;
  background-color: ${backgroundGrey};
`

const iconCss = css`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

export type DefaultMenusProps = {
  active: string
  isMobile: boolean
  menus: Menus
  onNavLinkClick: (route: string) => void
}

const menuHeaderStyle = css(`margin-bottom: 5px; font-weight: bold; padding-left: 10px; padding-top: 5px`)

const HorizontalMenus = ({ menus, isMobile, active }: DefaultMenusProps) => {
  return (
    <Popup
      content={
        <div className={popupCss}>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.analyze" />
            </Menu.Header>
            <AnalyzeItems menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.search" />
            </Menu.Header>
            <SearchItems menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.check" />
            </Menu.Header>
            <InsightsItems menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.dashboard" />
            </Menu.Header>
            <DashboardItem menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.buildYourOwnCharts" />
            </Menu.Header>
            <BuildYourOwnChartsItem menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
          <div>
            <Menu.Header className={menuHeaderStyle}>
              <Trans id="sidebar.buttons.resources" />
            </Menu.Header>
            <ResourcesItems menus={menus} minimizedSideBar={true} isMobile={isMobile} active={active} />
          </div>
        </div>
      }
      position="bottom right"
      flowing
      hoverable
      trigger={
        <div className={iconCss}>
          <Icon name="bars" />
        </div>
      }
    />
  )
}

export default HorizontalMenus
