import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { iconCss, menuCss, submenuCss } from '@pp/common/components/navigation/sidebar/sidebar.css'
import { LanguageEnum } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { Menu, Popup } from 'semantic-ui-react'

const styles = css`
  ${iconCss}

  .dropdown.icon {
    display: none !important;
  }
`
const languageOptions = [
  { text: 'EN', value: LanguageEnum.EN },
  { text: '中文', value: LanguageEnum.CN },
]

function SidebarLanguageSelector({ isMobile }: { isMobile: boolean }) {
  const { applicationStore } = useStores()

  const changeLanguage = (value: LanguageEnum) => {
    applicationStore.setLanguage(value)
    window.location.reload()
  }

  return (
    <Popup
      content={
        <>
          <Menu.Header className={menuCss}>
            <Trans id="sidebarLanguageSelector.label.text" />
          </Menu.Header>
          <Menu.Menu className={cx(submenuCss(false, isMobile, true))}>
            {languageOptions.map((el) => (
              <Menu.Item key={el.text}>
                <span
                  className={applicationStore.language === el.value ? 'active' : ''}
                  onClick={() => changeLanguage(el.value)}
                >
                  {el.text === 'EN' ? 'English' : el.text}
                </span>
              </Menu.Item>
            ))}
          </Menu.Menu>
        </>
      }
      position={applicationStore.minimizedSideBar ? 'right center' : 'top center'}
      flowing
      hoverable
      trigger={
        <div className={styles}>
          {languageOptions.find((el) => el.value === applicationStore.language)?.text || null}
        </div>
      }
    />
  )
}

export default SidebarLanguageSelector
