import { BIGGER_MOBILE_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from './breakpoints'

export function increaseSpecificity(cssString: string): string {
  return `&&&&&& {
          ${cssString}
      }`
}

export const sidebarWidth = '170px'
export const minimizedSidebarWidth = '60px'
export const mobileHeaderHeight = '50px'
export const mobileMediaQuery = `only screen and (max-width: ${MOBILE_BREAKPOINT}px)`
export const biggerMobileMediaQuery = `only screen and (max-width: ${BIGGER_MOBILE_BREAKPOINT}px)`
export const tabletMediaQuery = `only screen and (max-width: ${TABLET_BREAKPOINT}px)`
export const tinyHeightQuery = `only screen and (max-height: 400px)`
export const sidebarHeightBreakpoint = 'only screen and (max-height: 474px)'
export const sidebarWidthBreakpoint = 'only screen and (max-width: 992px)'
export const smallHeightQuery = `only screen and (max-height: 650px)`
export const midHeightQuery = `only screen and (max-height: 750px)`
export const percentageBarChartsQuery = 'only screen and (max-width: 1500px) and (min-width: 501px)'

export const pageWidth = '85%'
export const smallerMarginWidth = '95%'
export const pdfPageWidth = '100%'
