import { CSSProperties } from 'react'

import { css } from '@emotion/css'
import { backgroundGrey, lightGrey, primaryGreen, white } from '@pp/common/css/colors'
import { increaseSpecificity, sidebarWidth } from '@pp/common/css/css.helper'

export const disabledStyleCss = (minimizedSideBar: boolean) => css`
  color: #4d4d4d;
  font-size: 1em;
  display: block;
  cursor: pointer;
  background-color: transparent;
  padding: 10px 10px 10px 10px;
  ${!minimizedSideBar && 'border-left: 5px solid transparent; padding: 10px 10px 10px 30px;'}
`

export const disabledIconCss: CSSProperties = {
  padding: '5px',
  marginBottom: '20px',
  color: primaryGreen,
  fontSize: '1.5em',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const buttonCss = increaseSpecificity(`
  padding: 8px 5px;
`)

export const nonAuthenticatedIconCss = css`
  ${increaseSpecificity(`
  color: ${lightGrey};
  font-size: 1.25em;
  padding: 2px;
  pointer-events: none;
  .icon {
    margin: 0;
  }
  `)}
`

export const iconCss = css`
  font-size: 1.25em;
  padding: 2px;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;

  &.active {
    border-bottom: 3px solid ${primaryGreen};
  }

  &:hover {
    border-bottom: 3px solid ${primaryGreen};
  }

  .icon {
    margin: 0;
  }
`

export const sidebarCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  border: none;
  background-color: ${backgroundGrey};
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
  width: ${isMobile ? '100%' : sidebarWidth};
  ${!isMobile && 'transition: width 0.6s;'}
`)}
`

export const sliderCss = css`
  ${increaseSpecificity(`
  width: 60px
`)}
`

export const buttonMenuItemCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  padding: 5px 10px;
  font-size: 1em;

  width: ${!isMobile && sidebarWidth};
  text-align: center;

  &:before {
      background-color: ${backgroundGrey};
  }
`)}
`
export const flexContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
`

export const submenuCss = (active = false, isMobile: boolean, minimizedSideBar: boolean) =>
  css`
    ${increaseSpecificity(`
  background-color: ${active ? white : backgroundGrey};
  border-radius: 0;
  margin: 0;
  padding: 0;

  ${isMobile && 'display: flex; flex-direction: column; justify-content: center; align-items: center'};

  .item {
    border-top-color: #e0e1e2;
    padding: 0;
  }

  a,button,span {
    font-size: 1em;
    border-left: ${isMobile || minimizedSideBar ? 'none' : '5px solid transparent'};
    padding: 10px 10px 10px ${isMobile || minimizedSideBar ? '10px' : '30px'};
    color: #4d4d4d;
    display: block;
    cursor: pointer;

    &.active:hover {
      border-left: ${isMobile || minimizedSideBar ? 'none' : `5px solid ${primaryGreen}`};
      font-weight: bold;
      color: ${primaryGreen};
    }

    &.active {
      font-weight: bold;
      color: ${primaryGreen};
    }

    &:visited: {
      color: #4d4d4d;
    }
    &:hover {
      color: ${primaryGreen};
		  border-left: ${isMobile || minimizedSideBar ? 'none' : `5px solid ${primaryGreen}`};
    }
  }
`)}
  `

export const menuHeaderCss = (isMobile: boolean, minimizedSideBar: boolean) => css`
  ${increaseSpecificity(`
    margin: 0 !important;
    border-left: ${isMobile ? 'none' : '5px solid transparent'};
    cursor: pointer;
    color: #4d4d4d;
    padding: 12px 5px;
    ${isMobile ? 'font-size: 1.6em !important' : 'font-size: 1.2em !important'};
    display: flex;
    align-items: center;
    ${(minimizedSideBar || isMobile) && 'justify-content: center'};
    ${isMobile ? 'width: 200px;' : ''}

    &.active {
      color: white;
      background-color: ${primaryGreen};
    }

    &.active:hover {
      border-left: ${isMobile ? 'none' : '5px solid white'};
      color: white;
    }

    &:hover {
      color: ${primaryGreen};
      border-left: ${isMobile ? 'none' : `5px solid ${primaryGreen}`};
    }
  `)}
`

export const slideOutCss = (submenuVisible: boolean) => css`
  ${increaseSpecificity(`
    overflow: hidden;
    max-height: ${submenuVisible ? '150px' : '0'};
    transition: max-height 0.3s ease;
  `)}
`

export const logoContainerCss = (isMobile: boolean) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${isMobile ? 'center' : 'left'};
`

export const loginMessageCss = css`
  margin: 0px 20px;
  font-weight: bold;
`

export const minimizedSidebarCss = css`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  :hover {
    color: ${primaryGreen};
    cursor: pointer;
  }
`

export const buttonsContainerCss = css`
  display: flex;
  justify-content: center;
`

export const menuCss = css`
  margin-bottom: 5px;
`
