import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import { disabledStyleCss, slideOutCss, submenuCss } from '../../../sidebar.css'
import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const DashboardItem = ({ menus, minimizedSideBar, isMobile, active }: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()
  return (
    <Menu.Menu
      className={cx(submenuCss(false, isMobile, minimizedSideBar), !minimizedSideBar && slideOutCss(menus.searchMenu))}
    >
      {applicationStore.hasDashboards ? (
        <Menu.Item>
          <NavLink className={active.includes(routes.dashboardsRoute) ? 'active' : ''} to={routes.dashboardsRoute}>
            <Trans id="sidebar.buttons.dashboard" />
          </NavLink>
        </Menu.Item>
      ) : (
        <div
          className={disabledStyleCss(minimizedSideBar)}
          onClick={() => applicationStore.setModal({ open: true, version: '' })}
        >
          <Trans id="sidebar.buttons.dashboard" />
        </div>
      )}
    </Menu.Menu>
  )
}

export default DashboardItem
