import { midHeightQuery, mobileMediaQuery } from '@pp/common/css/css.helper'
import { useToggleFamilyType } from '@pp/common/custom-hooks/useToggleFamilyType.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { Menu } from 'semantic-ui-react'

import { IpButton, PatentButton, PopupSmallProductsButtons, TrademarkButton } from '.'
import { buttonMenuItemCss } from '../sidebar.css'

const ProductsButtons = ({ minimizedSideBar }: { minimizedSideBar: boolean }) => {
  useToggleFamilyType()
  const isSmallHight = useMediaQuery(midHeightQuery)
  const isMobile = useMediaQuery(mobileMediaQuery)

  return !isMobile && (isSmallHight || minimizedSideBar) ? (
    <PopupSmallProductsButtons minimizedSideBar={minimizedSideBar} />
  ) : (
    <>
      <Menu.Item className={buttonMenuItemCss(isMobile)}>
        <PatentButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
      </Menu.Item>
      <Menu.Item className={buttonMenuItemCss(isMobile)}>
        <TrademarkButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
      </Menu.Item>
      <Menu.Item className={buttonMenuItemCss(isMobile)} style={{ marginBottom: '10px' }}>
        <IpButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />
      </Menu.Item>
    </>
  )
}

export default observer(ProductsButtons)
