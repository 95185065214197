import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Menu, Icon, Popup } from 'semantic-ui-react'

import SearchItems from './SearchItems'
import { searchRoutes } from '../../../route-groups'
import { menuCss, menuHeaderCss } from '../../../sidebar.css'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const SearchMenu = ({
  active,
  onNavLinkClick,
  minimizedSideBar,
  isSmallHeight,
  menus,
  setShowMenus,
  isMobile,
}: MenuProps) => {
  const { applicationStore } = useStores()

  const handleClick = () => {
    if (isSmallHeight && !minimizedSideBar) {
      setShowMenus((prevState) => ({
        analyzeMenu: false,
        searchMenu: !prevState.searchMenu,
        checkMenu: false,
        resourceSubmenu: false,
      }))
    } else {
      if (applicationStore.hasStandardSearch) onNavLinkClick(routes.advancedSearchRoute)
      else applicationStore.setModal({ open: true, version: '' })
    }
  }

  return (
    <>
      <Popup
        content={
          <>
            <Menu.Header className={menuCss}>
              <Trans id="sidebar.buttons.search" />
            </Menu.Header>
            <SearchItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
          </>
        }
        disabled={!minimizedSideBar}
        position="right center"
        flowing
        hoverable
        trigger={
          <Menu.Header
            className={cx(menuHeaderCss(isMobile, minimizedSideBar), searchRoutes.indexOf(active) > -1 ? 'active' : '')}
            onClick={handleClick}
          >
            <Icon name="search" />
            {minimizedSideBar ? null : <Trans id="sidebar.buttons.search" />}
          </Menu.Header>
        }
      />

      {minimizedSideBar ? null : (
        <SearchItems menus={menus} minimizedSideBar={minimizedSideBar} isMobile={isMobile} active={active} />
      )}
    </>
  )
}

export default observer(SearchMenu)
