import { useAuth0 } from '@auth0/auth0-react'
import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { NavLink } from 'react-router-dom'
import { Menu, Label } from 'semantic-ui-react'

import { submenuCss, slideOutCss, disabledStyleCss } from '../../../sidebar.css'
import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const ResourcesItems = ({ menus, minimizedSideBar, isMobile, active }: DefaultMenuItemsProps) => {
  const { isAuthenticated } = useAuth0()
  const { applicationStore } = useStores()
  return (
    <Menu.Menu
      className={cx(
        submenuCss(false, isMobile, minimizedSideBar),
        !minimizedSideBar && slideOutCss(menus.resourceSubmenu),
      )}
    >
      <Menu.Item>
        {isAuthenticated ? (
          <a
            href="https://www.ip-pilot.com/en/help-center/"
            target="_blank"
            rel="noreferrer"
            style={{ cursor: 'pointer' }}
          >
            <Trans id="sidebar.menu.helpCenter" />
            <Label color="orange" size="mini" style={{ marginLeft: '0.3rem' }}>
              <Trans id="New" />
            </Label>
          </a>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.helpCenter" />
          </div>
        )}
      </Menu.Item>

      <Menu.Item>
        {isAuthenticated ? (
          <NavLink className={routes.dataCoverageRoute.includes(active) ? 'active' : ''} to={routes.dataCoverageRoute}>
            <Trans id="sidebar.menu.dataCoverage" />
          </NavLink>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.dataCoverage" />
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {isAuthenticated ? (
          <a
            href="https://www.ip-pilot.com/en/help-center/whats-new/"
            target="_blank"
            rel="noreferrer"
            style={{ cursor: 'pointer' }}
          >
            <Trans id="sidebar.menu.whatsNew" />
          </a>
        ) : (
          <div
            className={disabledStyleCss(minimizedSideBar)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.whatsNew" />
          </div>
        )}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default ResourcesItems
