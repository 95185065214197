import { css } from '@emotion/css'
import { t } from '@lingui/macro'
import { Popup } from 'semantic-ui-react'

import { PatentButton, TrademarkButton, IpButton } from '.'

const containerCss = (minimizedSideBar: boolean, isSidebarBreakpoint: boolean) => css`
  display: flex;
  margin: 1rem 0 1rem 0;
  ${minimizedSideBar && !isSidebarBreakpoint && 'flex-direction: column'};
  row-gap: 10px;
  ${isSidebarBreakpoint && 'column-gap: 10px'}
`

type Props = { isMobile: boolean; isSidebarBreakpoint: boolean; minimizedSideBar: boolean }

const SmallProductsButtons = ({ isMobile, minimizedSideBar, isSidebarBreakpoint }: Props) => {
  return (
    <div className={containerCss(minimizedSideBar, isSidebarBreakpoint)}>
      <Popup
        content={t`sidebar.buttons.patents`}
        trigger={<PatentButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />}
      />
      <Popup
        content={t`sidebar.buttons.trademarks`}
        trigger={<TrademarkButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />}
      />
      <Popup
        content={t`sidebar.buttons.ippilot`}
        trigger={<IpButton isMobile={isMobile} minimizedSideBar={minimizedSideBar} />}
      />
    </div>
  )
}

export default SmallProductsButtons
