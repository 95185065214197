import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { arrayFromString } from '@pp/common/helpers'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

import { Results } from '../../common/components/SimilarityUiState'

export type ServerFilters = {
  date_from: string[]
  date_to: string[]
  date_type: DateType[]
  family_type: FamilyType[]
  rep_url: string[]
  target_rep_country?: string[] | undefined
}

export type SimilaritySearchParams = {
  any: []
  dateType: string
  rep_country: string[]
  yearFrom: number
  yearTo: number
}

export type SearchParameterKeys = keyof SimilaritySearchParams
export type OptionalSimilaritySearchParams = Partial<SimilaritySearchParams>

export type SimilaritySearchInterface = {
  focalFirms: EntitySearchResult[]
  limitingClauses: SimilarityLimitingClause[]
  results: Partial<Results> | null
  searchParameters: SimilaritySearchParams
}

export enum SimilarityLimitingClause {
  Any = 'any',
  RepCountry = 'rep_country',
}

const initialSearchParameters: SimilaritySearchParams = {
  dateType: 'm',
  yearFrom: 2015,
  yearTo: new Date().getFullYear(),
  rep_country: [],
  any: [],
}

const newFilters = [{ prop: 'rep_country' as const, value: SimilarityLimitingClause.RepCountry }]

export class SimilarityAgentsSearchStore implements SimilaritySearchInterface {
  private rootStore: RootStoreInterface
  focalFirms: EntitySearchResult[] = []
  limitingClauses: SimilarityLimitingClause[] = []
  results: Partial<Results> | null = null
  searchParameters = initialSearchParameters

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get serverFilters() {
    const filter = {
      family_type: this.rootStore.applicationStore.familyType,
      ...(this.searchParameters.rep_country.length && { target_rep_country: this.searchParameters.rep_country }),
      date_from: [this.searchParameters.yearFrom.toString()],
      date_to: [this.searchParameters.yearTo.toString()],
      date_type: [this.searchParameters.dateType.toUpperCase() as DateType],
      rep_url: this.focalFirms.map((el) => el.value),
    }

    return filter
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => (this.focalFirms = focalFirms)
  setLimitingClauses = (clauses: SimilarityLimitingClause[]) => (this.limitingClauses = clauses)
  setResults = (results: Partial<Results> | null) => (this.results = results)

  setSearchParameter = (searchParameter: OptionalSimilaritySearchParams) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: SearchParameterKeys) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter] as never
  }

  resetSearchUI = () => {
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const familyType = urlSearchParams.get('familyType')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) this.limitingClauses = limitingClauses.split(',') as SimilarityLimitingClause[]

    this.searchParameters.yearFrom = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.yearFrom

    this.searchParameters.yearTo = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.yearTo

    this.searchParameters.dateType = urlSearchParams.get('date_type') || initialSearchParameters.dateType

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })
  }

  updateSearchParamsFromFilters = (filters: ServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]

    if (filters.rep_url.length) searchStrings.push(`rep_url=${filters.rep_url.join(',')}`)
    if (this.limitingClauses.length) searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    if (filters.target_rep_country?.length) searchStrings.push(`rep_country=${filters.target_rep_country.join(',')}`)

    searchStrings.push(`date_from=${filters.date_from}`)
    searchStrings.push(`date_to=${filters.date_to}`)
    searchStrings.push(`date_type=${filters.date_type}`)

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?familyType=${familyType}&${searchStrings.join('&')}`)
  }
}
